import { lazy, Suspense } from 'react';
import Pane from '../../component/Layout/PaneFixedHeader';
import EquipmentListSkeleton from './components/EquipmentList/EquipmentListSkeleton';
import { Layout } from './components/Layout';
import Details from './pages/Details';
import { useSelection } from './selection/SelectionContext';

const EquipmentListPane = lazy(
  () => import('./components/EquipmentList/EquipmentListPane')
);

const FleetOverview = () => (
  <Layout
    list={
      <Suspense fallback={<EquipmentListSkeleton />}>
        <EquipmentListPane />
      </Suspense>
    }
    main={<DetailsPane />}
    breakpoints={{
      medium: {
        maxWidth: 899,
      },
      large: {
        minWidth: 900,
        maxWidth: 1599,
      },
      xlarge: {
        minWidth: 1600,
      },
    }}
  />
);

export default FleetOverview;

const DetailsPane = () => {
  const { selected } = useSelection();

  return (
    <Pane>
      <Details equipmentIds={selected} />
    </Pane>
  );
};
