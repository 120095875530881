import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import type { ReactNode } from 'react';
import { memo } from 'react';
import { ColumnInstance } from 'react-table';
import { useTexts } from '../../feature/lang';
import { Icon } from '../../icon/Icon';
import { isNonEmptyArray } from '../../util/array';
import { Box } from '../Box';
import Checkbox from '../Checkbox';
import Popper from '../Popper';
import Translate from '../Translate';
import { useDataProvider } from './hook';

const ConfigureColumns = memo(() => {
  const {
    component: { dataGrid },
  } = useTexts();

  return (
    <Popper
      placement="auto"
      Trigger={({ onClick }) => (
        <Button onClick={onClick} color="grey" variant="contained">
          <Translate text={dataGrid.columnSelectionButton} />
        </Button>
      )}
      Content={Content}
    />
  );
});

export default ConfigureColumns;

const Content = () => {
  const { tableProps, setColumnOrder } = useDataProvider();
  const offset = tableProps.allColumns.length - tableProps.columns.length;
  const lastColumn = tableProps.visibleColumns.length - offset === 1;
  const columnIds = isNonEmptyArray(tableProps.state.columnOrder)
    ? tableProps.state.columnOrder.slice(0)
    : tableProps.allColumns.map((col) => col.id);
  const orderedColumnsArray = tableProps.columns
    .slice(0)
    .sort((a, b) => columnIds.indexOf(a.id) - columnIds.indexOf(b.id));
  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );

  return (
    <Paper
      elevation={8}
      sx={{
        maxHeight: '95vh',
        overflow: 'auto',
      }}
    >
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[
            restrictToVerticalAxis,
            restrictToFirstScrollableAncestor,
          ]}
        >
          <SortableContext
            items={orderedColumnsArray}
            strategy={verticalListSortingStrategy}
          >
            <List dense>
              {orderedColumnsArray.map((column) => (
                <ColumnItem
                  column={column}
                  lastColumn={lastColumn}
                  key={column.id}
                />
              ))}
            </List>
          </SortableContext>
        </DndContext>
      </div>
    </Paper>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = columnIds.indexOf(active.id);
      const newIndex = columnIds.indexOf(over?.id);

      setColumnOrder(arrayMove(columnIds, oldIndex, newIndex));
    }
  }
};

interface ColumnItemProps {
  column: ColumnInstance;
  lastColumn: boolean;
}

function ColumnItem({ column, lastColumn }: ColumnItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: column.id });

  const style = {
    touchAction: 'none',
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const props = column.getToggleHiddenProps();

  return (
    <ListItem
      disablePadding
      style={style}
      ref={setNodeRef}
      secondaryAction={
        <div {...attributes} {...listeners}>
          <Box sx={{ cursor: 'grab' }}>
            <Icon name="drag_indicator" size="small" />
          </Box>
        </div>
      }
    >
      <ListItemButton
        onClick={() => {
          if (props.checked) {
            // clear filter when hiding a column
            column.setFilter?.(undefined);
          }
          column.toggleHidden(props.checked);
        }}
      >
        <ListItemIcon sx={{ minWidth: 'unset' }}>
          <Checkbox
            checked={props.checked}
            disabled={lastColumn && props.checked}
            disableRipple
          />
        </ListItemIcon>
        <ListItemText>{column.render('ColumnName') as ReactNode}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
