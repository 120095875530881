import type { ReactNode } from 'react';
import { useCurrentUserQuery } from '../../feature/user/queries';
import SignUserAgreementDialog from '../../view/agreement/SignUserAgreementDialog';
import FullscreenProgress from '../Progress/FullscreenProgress';

interface RequiredActionsProps {
  children: ReactNode;
}

/**
 * Actions required by the user before using the app. Sign terms of service,
 * enter user details are examples of actions.
 *
 * The components shows dialog for the first required action. When resolved the
 * currently logged in user's information is refetched and the next - if any -
 * required action is displayed.
 *
 * @param children
 * @constructor
 */
const RequiredActions = ({ children }: RequiredActionsProps) => {
  const { refetch, data: user, status } = useCurrentUserQuery();

  function handleDone() {
    refetch();
  }

  if (status === 'loading') {
    return <FullscreenProgress isVisible />;
  }

  const signActions =
    user?.actions?.filter((action) => action.type === 'sign_document') ?? [];

  if (signActions.length) {
    return (
      <SignUserAgreementDialog onDone={handleDone} signActions={signActions} />
    );
  }

  return <>{children}</>;
};

export default RequiredActions;
