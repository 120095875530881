import { get as getV2 } from '../../api/v2';
import { post, get } from '../../api/apiClient';
import { toQueryParams } from '../../api/util';

type DateRangeDto = {
  range_from: string;
  range_to: string;
};

interface FetchOrderOptions {
  customerId?: string;
  status?: OrderStatus[];
  dateRange?: {
    range_from: Date;
    range_to: Date;
  };
  limit?: number;
}

const defaultParams = {
  limit: 1000,
  view: 'full',
};

export const fetchOrders = ({
  customerId,
  status,
  dateRange,
  limit,
}: FetchOrderOptions) => {
  const formattedDateRange: DateRangeDto | undefined = dateRange
    ? {
        range_from: dateRange.range_from.toISOString(),
        range_to: dateRange.range_to.toISOString(),
      }
    : undefined;

  const params = {
    ...defaultParams,
    ...formattedDateRange,
    range_selector: ['created'],
    customer_id: customerId,
    status,
    limit,
  };
  return getV2<OrderDetailsDto[]>(`ecom/salesorder${toQueryParams(params)}`, {
    defaultValue: [],
  });
};

export const fetchOrderById = (orderId: string) =>
  get<OrderDetailsDto>(`ecom/salesorder/${orderId}`);

export const downloadInvoice = (orderId: string, invoiceNumber: string) =>
  get(`ecom/salesorder/${orderId}/invoice/${invoiceNumber}`, {
    requestInit: { headers: { Accept: 'application/pdf' } },
    responseHandler: () => (response) =>
      // https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
      response.blob().then((resObj) => {
        // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
        const newBlob = new Blob([resObj], { type: 'application/pdf' });

        // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // @ts-ignore
          window.navigator.msSaveOrOpenBlob(newBlob);
        } else {
          // For other browsers: create a link pointing to the ObjectURL containing the blob.
          const objUrl = window.URL.createObjectURL(newBlob);

          let link = document.createElement('a');
          link.href = objUrl;
          link.download = invoiceNumber;
          link.click();

          // For Firefox it is necessary to delay revoking the ObjectURL.
          setTimeout(() => {
            window.URL.revokeObjectURL(objUrl);
          }, 250);
        }
      }),
  });

export const createOrder = (order: OrderCreateData) =>
  post<OrderDetailsDto>('ecom/salesorder', order);
