import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import type { AlarmSeverity } from '../../../../feature/alarm/api.types';
import { useSeverityColor } from '../../../../theme/hooks';

const Root = styled('div', { name: 'Severity', slot: 'Root' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  height: '100%',
}));

const Circle = styled('div', { name: 'Severity', slot: 'Circle' })({
  height: 20,
  width: 20,
  borderRadius: '100%',
});

interface SeverityProps {
  severity: AlarmSeverity;
  label: string;
}

const Severity = ({ severity, label }: SeverityProps) => {
  const color = useSeverityColor()(severity);

  return (
    <Root>
      <Circle sx={{ backgroundColor: color.main }} />
      <Typography variant="subtitle2">{label}</Typography>
    </Root>
  );
};

export default Severity;
