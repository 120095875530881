import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionGroup } from 'react-transition-group';
import Context from './context';
import Toast from './Toast';
import { add, subscribe } from './toastManager';
import type { IToastModel } from './types';

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: '50%',
  bottom: theme.spacing(2),
  transform: 'translateX(-50%)',
  zIndex: 1500,
}));

export default ({ children }: any) => {
  const [toasts, setToasts] = useState<IToastModel[]>([]);

  useEffect(
    () =>
      subscribe((toasts: IToastModel[]) => {
        setToasts(toasts);
      }),
    []
  );

  return (
    <Context.Provider value={{ notify: add }}>
      {children}
      <Container>
        <TransitionGroup>
          {toasts.reverse().map((toast) => (
            <Collapse key={toast.id}>
              <Toast {...toast} />
            </Collapse>
          ))}
        </TransitionGroup>
      </Container>
    </Context.Provider>
  );
};
