import { FeatureName } from './index';

export default [
  'admin.hammer-configuration',
  'admin.screen-configuration',
  'feedback',
  'fleet.alarm-signal-intervals',
  'fleet.alarm-details',
  'fleet.claims',
  'fleet.screens-essentials-stream-data',
  'new-data-grid',
  'visualisation.line-chart-alarms',
] as const;

export const labels: Record<FeatureName, string> = {
  'admin.hammer-configuration': 'Hammer configuration (Ärdy)',
  'admin.screen-configuration': 'Screen configuration',
  feedback: 'Submit feedback',
  'fleet.alarm-signal-intervals': 'Alarm thresholds',
  'fleet.alarm-details': 'Alarm details',
  'fleet.claims': 'Warranty claims',
  'fleet.screens-essentials-stream-data': 'Stream data in screens header',
  'new-data-grid': 'New table',
  'visualisation.line-chart-alarms': 'Alarms in line charts',
};
