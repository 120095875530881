import type { Translation } from '../../feature/lang/types';
import { ToastOptions } from './hook';

export interface IToastModel extends IToast {
  remove: () => void;
  id: number;
}

export interface IToast extends ToastOptions {
  message: Translation;
  type?: ToastType;
  //used to resolve a translation path
  path?: boolean;
}

export enum ToastType {
  Info = 'Info',
  Error = 'Error',
  Success = 'Success',
}

export interface ToastAction {
  label: Translation;
  onClick: () => void;
}
