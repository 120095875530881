import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import type { ReactNode } from 'react';
import FeaturesActiveChip from '../../feature/features/FeaturesActiveChip';
import { useTexts } from '../../feature/lang';
import { Icon } from '../../icon/Icon';
import { mainNavItems } from '../../view/mainNavItems';
import { appBarHeight } from '../AppBar/AppBar';
import { Box } from '../Box';
import IconButton from '../Button/IconButton';
import { DevChip } from '../Chip/Chip';
import Divider from '../Divider';
import { Navigation } from '../Navigation';
import Tooltip from '../Tooltip';
import { ActAsCustomerInfo } from './ActAsCustomer';
import { drawerWidth, railWidth, transitionIn, transitionOut } from './layout';
import { useRail } from './useRail';

const Drawer = () => {
  const [rail] = useRail();

  return (
    <MuiDrawer
      variant="permanent"
      sx={(theme) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiPaper-root': {
          overflow: 'hidden',
          borderRight: `1px solid ${theme.palette.divider}`,

          ...(!rail && {
            width: drawerWidth,
            transition: transitionIn(theme, 'width'),
          }),
          ...(rail && {
            width: railWidth,
            transition: transitionOut(theme, 'width'),
          }),
        },
      })}
    >
      <Toolbar sx={{ minHeight: `${appBarHeight}px !important` }} />
      <Box flex={1}>
        <Navigation navigation={mainNavItems} />
      </Box>
      <Chips />
      <ActAsCustomerInfo />
      <Divider />
      <Footer>
        <ToggleRail />
      </Footer>
    </MuiDrawer>
  );
};

export default Drawer;

function ToggleRail() {
  const texts = useTexts().component.drawer;
  const [rail, setRail] = useRail();

  return (
    <Tooltip
      title={rail ? texts.expand.tooltip : texts.collapse.tooltip}
      disableInteractive
    >
      <IconButton onClick={() => setRail(!rail)} size="large">
        <Icon name={rail ? 'chevron_right' : 'chevron_left'} />
      </IconButton>
    </Tooltip>
  );
}

const Chips = () => {
  const [rail] = useRail();

  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          my: 1,
          mx: 2,
        },
        rail && {
          flexDirection: 'column',
          gap: 4,
          mx: 1,
        },
      ]}
    >
      <FeaturesActiveChip vertical={rail} />
      <DevChip vertical={rail} />
    </Box>
  );
};

const Footer = ({ children }: { children: ReactNode }) => {
  const [rail] = useRail();

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      flexDirection={rail ? 'column' : 'row'}
      alignItems="center"
      gap={0.5}
      margin={1}
    >
      {children}
    </Box>
  );
};
