import { useMemo } from 'react';
import { useFeatures } from '../../feature/features';
import { usePermissions } from '../../feature/permissions/PermissionsProvider';
import { NavItem } from './types';

export const useFilteredNavItems = (navItems: NavItem[]) => {
  const isItemVisible = useIsItemVisible();

  return useMemo(
    () =>
      navItems
        .filter((item) => isItemVisible(item))
        .map((item) => ({
          ...item,
          subItems: item.subItems?.filter((subItem) => isItemVisible(subItem)),
        })),
    [navItems, isItemVisible]
  );
};

const useIsItemVisible = () => {
  const permissions = usePermissions();
  const features = useFeatures();

  return useMemo(
    () => (item: NavItem) => {
      if (item.access && !item.access(permissions)) {
        return false;
      }

      if (item.featureActive && !item.featureActive(features)) {
        return false;
      }

      return true;
    },
    [permissions, features]
  );
};
