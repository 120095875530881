import { Button, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import useAuth from '../../feature/auth/useAuth';
import DecorateInsightsWithUser from '../../feature/insights/DecorateInsightsWithUser';
import { useTranslation } from '../../feature/lang';
import { useGlobalPermits } from '../../feature/permissions/useGlobalPermits';
import { every, some } from '../../feature/query/util';
import { useCurrentUserQuery } from '../../feature/user/queries';
import { Box } from '../Box';
import FullscreenProgress from '../Progress/FullscreenProgress';
import RequiredActions from './RequiredActions';

interface AppStartupProps {
  children: ReactNode;
}

/**
 * Ensures user is authenticated and loads data about context user. The app is
 * rendered once this process is completed.
 */
const AppStartup = ({ children }: AppStartupProps) => {
  const { login, logout, isAuthenticated, isInitialized } = useAuth();

  const { status: currentUserStatus, error: currentUserError } =
    useCurrentUserQuery({ enabled: isAuthenticated });
  const { status: permitStatus, error: permitError } = useGlobalPermits();
  const { isReady: isLangReady } = useTranslation();

  // Login once keycloak is initialized and user it not authenticated
  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      login();
    }
  }, [isInitialized, isAuthenticated, login]);

  // TODO Change to throw error in the future. Use Error Boundary to show it nicely.
  if (some('error', currentUserStatus, permitStatus)) {
    // @ts-ignore
    const errorMessage = currentUserError?.message || permitError?.message;
    return (
      <Box m={4} maxWidth="40rem">
        <Typography variant="h1" gutterBottom>
          Error During Startup
        </Typography>
        <Typography gutterBottom>
          While starting the app a problem was encountered.
        </Typography>
        <Typography variant="caption">Error message: </Typography>
        <Typography variant="caption">{errorMessage}</Typography>
        <Box my={2}>
          <Typography variant="h2" gutterBottom>
            Are you connected to a customer ?
          </Typography>
          <Typography gutterBottom>
            Make sure that the user you are logged in belongs to a customer.
            Either relate the user to a customer or login with another user.
          </Typography>
        </Box>
        <Box my={2}>
          <Button onClick={logout} variant="contained" color="primary">
            Logout
          </Button>
        </Box>
      </Box>
    );
  }

  const isAppReady =
    isAuthenticated &&
    isLangReady &&
    every('success', currentUserStatus, permitStatus);

  return (
    <>
      <FullscreenProgress isVisible={!isAppReady} />
      {isAppReady && (
        <>
          <DecorateInsightsWithUser />
          <RequiredActions>{children}</RequiredActions>
        </>
      )}
    </>
  );
};

export default AppStartup;
