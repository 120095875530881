import { useContext } from 'react';
import type {
  InterpolationValues,
  Translation,
} from '../../feature/lang/types';
import Context from './context';
import { IToast, ToastAction, ToastType } from './types';

export default () => {
  const { notify } = useContext(Context);

  const t =
    (type: ToastType) => (message: Translation, options?: ToastOptions) => {
      notify({ type, message, ...options });
    };

  const toast = notify as Toast;
  toast.success = t(ToastType.Success);
  toast.error = t(ToastType.Error);
  toast.info = t(ToastType.Info);

  return toast;
};

interface Toast {
  (toast: IToast): void;
  success: ToastByPass;
  error: ToastByPass;
  info: ToastByPass;
}

interface ToastByPass {
  (message: Translation, options?: ToastOptions): void;
}

export interface ToastOptions {
  values?: InterpolationValues;
  count?: number;
  actions?: ToastAction[];
  closeAfter?: number;
}
