import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { noop } from '../../util/fn';
import { useLocalStorage } from '../../util/useStorage';

interface AccordionContextProps {
  type: AccordionType;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
}

const AccordionContext = createContext<AccordionContextProps>({
  type: 'default',
  expanded: true,
  setExpanded: noop,
});

interface AccordionProviderProps {
  type: AccordionType;
  id?: string;
  defaultExpanded?: boolean;
  uncontrolledExpand: boolean;
  children: ReactNode;
}

export const AccordionProvider = ({
  type,
  id,
  defaultExpanded = false,
  uncontrolledExpand,
  children,
}: AccordionProviderProps) => {
  const [expanded, setExpanded] = useExpanded(
    id,
    defaultExpanded,
    uncontrolledExpand
  );

  return (
    <AccordionContext.Provider value={{ type, expanded, setExpanded }}>
      {children}
    </AccordionContext.Provider>
  );
};

export const useAccordion = () => useContext(AccordionContext);

// Handles expand state of accordion. It's either;
// (a) disabled if `uncontrolled` is true,
// (b) stored in local store if `id` is configured, otherwise
// (c) stored using `useState()`.
const useExpanded = (
  id: string | undefined,
  defaultExpanded: boolean,
  uncontrolled: boolean
) => {
  const storageId = id ? `${id}.expanded` : 'noop';
  const localStorage = useLocalStorage(storageId, defaultExpanded);
  const state = useState<boolean>(defaultExpanded);

  if (uncontrolled) {
    return [];
  }

  return id ? localStorage : state;
};
