import { flatten } from 'lodash-es';
import { toQueryParams } from '../../api/util';
import { get, post, put, remove } from '../../api/v1';
import { get as getV2, getPaginated as getPaginatedV2 } from '../../api/v2';
import { createChunks, isNonEmptyArray } from '../../util/array';
import { fetchLatestSamples } from '../samples/api';

type EquipmentBaseIncludes =
  | 'alarm_count'
  | 'com_device'
  | 'documents'
  | 'inspection_interval'
  | 'presentations'
  | 'product_image'
  | 'trip_meter';

export type EquipmentIncludeV1 = (
  | EquipmentBaseIncludes
  | 'load'
  | 'product_model_3d'
  | 'sensor_count'
  | 'rul'
)[];

export type EquipmentIncludeV2 = (EquipmentBaseIncludes | 'relations')[];

export const fetchEquipmentByEntityIds = (
  ids?: string[],
  include?: EquipmentIncludeV2
) => {
  if (!isNonEmptyArray(ids)) {
    return Promise.resolve([]);
  }

  const chunks = createChunks(ids, 10);

  return Promise.all(
    chunks.map((chunk) =>
      getPaginatedV2<Equipment>(
        'equipment',
        {
          entity_ids: chunk.join(','),
          include,
          page_size: 1000,
        },
        {},
        'page_number'
      ).then(flatten)
    )
  ).then((result) => flatten(result));
};

export const fetchEquipmentByIds = (
  equipmentIds?: string[],
  include?: EquipmentIncludeV1
) => {
  if (!isNonEmptyArray(equipmentIds)) {
    return Promise.resolve([]);
  }

  const chunks = createChunks(equipmentIds, 100);

  return Promise.all(
    chunks.map((chunk) => {
      const query = toQueryParams({ include, equipment_ids: chunk });
      return get<Equipment[]>(`equipment${query}`);
    })
  ).then((result) => flatten(result));
};

export const fetchIsValidRd3 = (rd3SerialNumber: string) =>
  getV2<boolean>(`configuration/isvalidrd3/${rd3SerialNumber}`);

export const fetchEquipmentSignalOffset = (equipmentId?: string) => {
  if (!equipmentId) {
    return Promise.resolve([]);
  }
  return get<SignalOffset[]>(`equipment/${equipmentId}/signaloffset`);
};

export const addEquipmentSignalOffset = (offset: Partial<SignalOffset>) => {
  if (!offset.equipmentId) {
    return Promise.resolve();
  }

  const postOffset = (offset: Partial<SignalOffset>) =>
    post(`equipment/${offset.equipmentId}/signaloffset`, offset);

  if (offset.createdAt) {
    return postOffset(offset);
  }

  return fetchLatestSamples([offset.equipmentId], {
    signal_names: [offset.signalName],
    max_age: 'P1D',
  }).then((samples) => {
    const sample = samples[0];

    return postOffset({
      createdAt: sample && sample.timeStamp,
      ...offset,
    });
  });
};

export const resetTripMeter = (equipmentId?: string) => {
  if (!equipmentId) {
    return Promise.resolve();
  }
  return post(`equipment/${equipmentId}/tripmeter`, undefined);
};

export const fetchReportingTime = (equipmentId?: string) => {
  if (!equipmentId) {
    return Promise.resolve(undefined);
  }
  return get<ReportingTime>(`equipment/${equipmentId}/reporting`);
};

export const updateReportingTime = (
  equipmentId?: string,
  reportingTime?: Partial<ReportingTime>
) => {
  if (!equipmentId) {
    return Promise.resolve();
  }
  return put(`equipment/${equipmentId}/reporting`, reportingTime);
};

export const fetchExternalEquipment = (serial?: string) => {
  if (!serial) {
    return Promise.resolve(undefined);
  }
  return get<EquipmentEntity>(
    `external/type/atd/equipment?serialNumber=${serial}`
  );
};

export const fetchEquipmentWarranty = (equipmentId?: string) => {
  if (!equipmentId) {
    return Promise.resolve(undefined);
  }
  return get<Warranty>(`equipment/${equipmentId}/warranty`);
};

export const updateEquipmentWarranty = ({
  equipmentId,
  warranty,
}: {
  equipmentId?: string;
  warranty: UpdateWarrantyInput;
}) => {
  if (!equipmentId) {
    return Promise.resolve();
  }
  return put(`equipment/${equipmentId}/warranty`, warranty);
};

export const deleteEquipmentWarranty = (equipmentId?: string) => {
  if (!equipmentId) {
    return Promise.resolve(undefined);
  }
  return remove(`equipment/${equipmentId}/warranty`);
};

export const fetchEquipmentTarget = (
  equipmentId: string,
  targetName: TargetName
) => {
  return get<EquipmentTargetData>(
    `equipment/${equipmentId}/target/${targetName}`
  );
};

export interface UpdateTargetData {
  name: TargetName;
  data: EquipmentTargetData;
}

export const updateEquipmentTargets = (
  equipmentId: string,
  targets: UpdateTargetData[]
) => {
  return Promise.all(
    targets.map((target) =>
      put(`equipment/${equipmentId}/target/${target.name}`, target.data)
    )
  );
};
