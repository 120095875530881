import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type {
  InterpolationValues,
  Translation,
} from '../../feature/lang/types';
import Translate from '../Translate';

const StyledTooltip = styled(({ className, ...props }: MuiTooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  '.MuiTooltip-tooltip': {
    whiteSpace: 'pre-line',
  },
});

interface TooltipProps extends Omit<MuiTooltipProps, 'title'> {
  title?: Translation;
  values?: InterpolationValues;
  count?: number;
}

const Tooltip = ({
  title,
  values,
  count,
  children,
  ...props
}: TooltipProps) => {
  return title ? (
    <StyledTooltip
      {...props}
      title={<Translate text={title} values={values} count={count} />}
    >
      {children}
    </StyledTooltip>
  ) : (
    children
  );
};

export default Tooltip;
