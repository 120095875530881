import MuiAccordionDetails, {
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import { subtract, toSxArray } from '../../theme/util';
import { useAccordion } from './AccordionProvider';

type Margin = 'normal' | 'none';

interface AccordionDetailsProps extends MuiAccordionDetailsProps {
  margin?: Margin;
}

const AccordionDetails = ({
  children,
  margin = 'normal',
  sx,
  ...rest
}: AccordionDetailsProps) => {
  const { expanded } = useAccordion();

  // Checking if expanded !== false as expanded === undefined value indicates
  // that accordion is running in uncontrolled mode.
  const showContent = expanded !== false;

  return (
    <MuiAccordionDetails
      sx={[
        {
          display: 'flex',
          flexFlow: 'column nowrap',
          borderBottomLeftRadius: ({ shape }) =>
            subtract(shape.borderRadius, 1),
          borderBottomRightRadius: ({ shape }) =>
            subtract(shape.borderRadius, 1),
          overflow: 'hidden',
        },
        margin === 'normal' && { padding: 2 },
        margin === 'none' && { padding: 0 },
        ...toSxArray(sx),
      ]}
      {...rest}
    >
      {showContent && children}
    </MuiAccordionDetails>
  );
};

export default AccordionDetails;
