import type { ThemeOptions } from '@mui/material';
import { merge } from 'lodash-es';
import type { GreyShade } from '../../muiThemeExtensions';
import { applyDefaultShadows } from '../util';
import { base } from './common';

const darkTheme: ThemeOptions = merge({}, base, {
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedInherit: {
          color: '#fff',
          borderColor: 'rgba(255, 255, 255, 0.23)',
          '&:hover': {
            borderColor: 'rgba(255, 255, 255, 0.23)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          // Adjust color for native datepicker icon <input type="date" ...>
          '::-webkit-calendar-picker-indicator': { filter: 'invert(100%)' },
          // Adjust color for native number up/down buttons <input type="number" ...>
          '::-webkit-inner-spin-button': { filter: 'invert(75%)' },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
        },
        elevation24: {
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    link: {
      primary: {
        default: '#FF6A00',
        hover: '#FF6A00',
        active: '#FF6A00',
      },
      nav: {
        default: '#b9b9b9',
        hover: '#fff',
        active: '#fff',
      },
    },
    background: {
      default: '#242424',
      inverted: 'rgba(255,255,255,0.38)',
      modal: '#333',
      paper: '#272727',
    },
    success: {
      light: '#032E15',
    },
    warning: {
      light: '#4A2300',
    },
    error: {
      light: '#450000',
    },
    info: {
      light: '#001C4A',
    },
    getGrey(shade: GreyShade) {
      switch (shade) {
        case 10:
          return 'rgba(255, 255, 255, 0.04)';
        case 20:
          return 'rgba(255, 255, 255, 0.09)';
        case 30:
          return 'rgba(255, 255, 255, 0.13)';
        case 40:
          return 'rgba(255, 255, 255, 0.18)';
      }
    },
    severity: {
      critical: {
        light: '#450000',
      },
      high: {
        light: '#551800',
      },
      medium: {
        light: '#4A2300',
      },
      low: {
        light: '#4A2D00',
      },
      info: {
        light: '#001C4A',
      },
      fallback: {
        main: '#94BBFF',
        light: '#001333',
      },
    },
  },
  shadows: applyDefaultShadows([
    '0px 0px 24px 0px rgba(0,0,0,0.3)',
    '0px 0px 24px 0px rgba(0,0,0,0.3)',
    '0px 0px 10px -5px rgba(0,0,0,0.08), 0px 0px 30px -5px rgba(0,0,0,0.2)',
  ]),
});

export default darkTheme;
