import { flatten } from 'lodash-es';
import { useMemo } from 'react';
import { createChunks } from '../../util/array';
import { ONE_HOUR, TEN_MINUTES } from '../../util/time';
import queryClient from '../query/queryClient';
import { useConcurrentQueries } from '../query/useConcurrentQueries';
import { fetchPrices } from './api';
import { useCachedPricesQuery } from './useCachedPricesQuery';
import { priceKey, pricesKey } from './usePriceQuery';

const chunkSize = 50;

const getQueryOptions = (articleIds: number[], deliveryPointId?: string) => ({
  queryKey: pricesKey(articleIds, deliveryPointId),
  queryFn: () =>
    fetchPrices(articleIds, deliveryPointId!).then((result) => {
      const missingIds = articleIds.filter(
        (articleId) => !result.some((price) => price.articleId === articleId)
      );
      const missingPrices = missingIds.map((articleId) =>
        getEmptyPrice(articleId)
      );
      return [...result, ...missingPrices];
    }),
  staleTime: TEN_MINUTES,
  cacheTime: ONE_HOUR,
  enabled: articleIds.length > 0 && !!deliveryPointId,
  onSuccess: (result: PriceAndAvailability[]) =>
    result.forEach((price) => {
      queryClient.setQueryData(
        priceKey(price.articleId, deliveryPointId),
        price
      );
    }),
});

export const usePrices = (
  allArticleIds: number[],
  deliveryPointId?: string,
  enabled: boolean = true
) => {
  const articleIds = useMemo(
    () => (enabled && deliveryPointId ? allArticleIds : []),
    [enabled, deliveryPointId, allArticleIds]
  );

  const { data: cachedPrices = [], isLoading: cacheIsLoading } =
    useCachedPricesQuery(articleIds, deliveryPointId);

  const articleIdsToQuery = articleIds.filter(
    (articleId) =>
      !cacheIsLoading &&
      !cachedPrices.some((price) => price.articleId === articleId)
  );
  const chunks = createChunks(articleIdsToQuery, chunkSize);
  const queries = chunks.map((chunk) =>
    getQueryOptions(chunk, deliveryPointId)
  );

  const queryResults = useConcurrentQueries({ queries });
  const fetchedPrices = flatten(queryResults.map(({ data }) => data ?? []));
  const isLoading =
    cacheIsLoading || queryResults.some(({ isLoading }) => isLoading);

  return { data: [...cachedPrices, ...fetchedPrices], isLoading };
};

export const usePrice = (
  articleId: number,
  deliveryPointId?: string,
  enabled?: boolean
) => {
  const { data, isLoading } = usePrices([articleId], deliveryPointId, enabled);

  return { data: data?.at(0), isLoading };
};

const getEmptyPrice = (articleId: number) => ({
  articleId,
  articleNumber: '',
  availability: {},
});
