import { Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import type { ChangeEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../component/Dialog';
import Translate from '../../component/Translate';
import { useTexts } from '../lang';
import { labels } from './features';
import { FeatureName } from './index';
import useFeatures from './useFeatures';

interface FeatureToggleDialogProps {
  open: boolean;
  onClose: () => void;
}

const FeaturesDialog = ({ open, onClose }: FeatureToggleDialogProps) => {
  const features = useFeatures();
  const texts = useTexts();

  const handleReset = () => {
    features.reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle title={texts.app.features.dialog.header} />
      <DialogContent>
        <FormGroup>
          {features.list.map((name) => (
            <FeatureSwitch name={name} key={name} />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions
        right={
          <>
            <Button variant="outlined" onClick={handleReset}>
              <Translate text={texts.component.button.common.reset} />
            </Button>
            <Button color="primary" variant="contained" onClick={onClose}>
              <Translate text={texts.component.button.common.ok} />
            </Button>
          </>
        }
      />
    </Dialog>
  );
};

export default FeaturesDialog;

interface FeatureSwitchProps {
  name: FeatureName;
}

const FeatureSwitch = ({ name }: FeatureSwitchProps) => {
  const features = useFeatures();

  const handleChange =
    (name: FeatureName) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        features.on(name);
      } else {
        features.off(name);
      }
    };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={features.includes(name)}
          onChange={handleChange(name)}
          color="primary"
          name={name}
        />
      }
      label={<FeatureLabel name={name} />}
      key={name}
    />
  );
};

interface FeatureLabelProps {
  name: FeatureName;
}

const FeatureLabel = ({ name }: FeatureLabelProps) => {
  return labels[name] ? <>{labels[name]}</> : <>{name}</>;
};
