import Typography from '@mui/material/Typography';
import Translate from '../../../component/Translate';
import useTranslation from '../../../feature/lang/hook';
import { ContentContainer } from '../components/ContentContainer';

interface DataAndSecurityProps {
  visible: boolean;
}

const DataAndSecurity = ({ visible }: DataAndSecurityProps) => {
  const { texts } = useTranslation();
  return (
    <ContentContainer visible={visible}>
      <Typography variant="h3" gutterBottom>
        <Translate text={texts.user.preferences.dataAndSecurity} />
      </Typography>
    </ContentContainer>
  );
};

export default DataAndSecurity;
