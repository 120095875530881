import { getMessage, isTranslatable } from './util';
import type { InterpolationValues, InterpolationValue } from './types';

export function interpolateValues(
  message: string,
  values: InterpolationValues = {}
) {
  Object.entries(values).forEach(([key, value]) => {
    message = message.replaceAll(`{{${key}}}`, toString(value));
  });

  return message;
}

function toString(value: InterpolationValue) {
  if (isTranslatable(value)) {
    return getMessage(value);
  }
  if (typeof value === 'number') {
    return `${value}`;
  }
  if (typeof value === 'undefined' || value === null) {
    return '';
  }
  return value;
}
