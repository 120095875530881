import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import '@microsoft/applicationinsights-shims';
import { Button } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'normalize.css';
import type { ReactElement } from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import AppStartup from './component/AppStartup/AppStartup';
import { ErrorBoundary } from './component/Error';
import { ToastContainer } from './component/Toast';
import Translate from './component/Translate';
import AccountProvider from './feature/account/AccountProvider';
import PrefetchData from './feature/app/PrefetchData';
import AuthProvider from './feature/auth/AuthProvider';
import keycloak from './feature/auth/keycloak';
import { BuildInfo } from './feature/build';
import { CartProvider } from './feature/cart';
import { CheckoutProvider } from './feature/checkout/context';
import { DataStreamManager } from './feature/dataStream/Manager';
import { features, FeatureToggle } from './feature/features';
import { reactPlugin } from './feature/insights/appInsights';
import { useTexts } from './feature/lang';
import LangProvider from './feature/lang/context';
import { PermissionsProvider } from './feature/permissions/PermissionsProvider';
import queryClient from './feature/query/queryClient';
import ThemeProvider from './theme/ThemeProvider';
import './util/debug';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <AuthProvider keycloak={keycloak}>
    <StrictMode>
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Router>
              <LangProvider defaultLanguage="en">
                <ErrorBoundaryWithAction>
                  <ToastContainer>
                    <AppStartup>
                      <PermissionsProvider>
                        <FeatureToggle features={features}>
                          <CartProvider>
                            <CheckoutProvider>
                              <AccountProvider>
                                <App />
                              </AccountProvider>
                              <PrefetchData />
                            </CheckoutProvider>
                          </CartProvider>
                        </FeatureToggle>
                      </PermissionsProvider>
                      <DataStreamManager />
                    </AppStartup>
                  </ToastContainer>
                </ErrorBoundaryWithAction>
              </LangProvider>
            </Router>
          </ThemeProvider>
          <BuildInfo />
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </StrictMode>
  </AuthProvider>
);

interface ErrorBoundaryWithActionProps {
  children: ReactElement;
}
function ErrorBoundaryWithAction({ children }: ErrorBoundaryWithActionProps) {
  const { app } = useTexts();

  return (
    <ErrorBoundary
      action={
        <Button href="/" variant="outlined">
          <Translate text={app.navigation.home.label} />
        </Button>
      }
    >
      {children}
    </ErrorBoundary>
  );
}
