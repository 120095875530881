import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import type { Translation } from '../../feature/lang/types';
import { toSxArray } from '../../theme/util';
import Translate from '../Translate';

const HeaderContainer = styled('div', {
  name: 'Dialog',
  slot: 'TitleHeaderContainer',
})(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const Flex = styled('div', { name: 'Dialog', slot: 'TitleFlex' })({
  flexGrow: 10,
});

interface DialogTitleProps
  extends Omit<MuiDialogTitleProps, 'title' | 'left' | 'right'> {
  title: Translation;
  subHeader?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

const DialogTitle = ({
  title,
  subHeader,
  left,
  right,
  ...rest
}: DialogTitleProps) => {
  return (
    <MuiDialogTitle
      {...rest}
      sx={[
        {
          display: 'flex',
          flexFlow: 'column nowrap',
          padding: 0,
        },
        ...toSxArray(rest.sx),
      ]}
    >
      <HeaderContainer>
        {left}
        <Header title={title} />
        <Flex />
        {right}
      </HeaderContainer>
      {subHeader}
    </MuiDialogTitle>
  );
};

const Header = ({ title }: Partial<DialogTitleProps>) => {
  return (
    <Typography
      variant="h3"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Translate text={title} />
    </Typography>
  );
};

export default DialogTitle;
