import Keycloak from 'keycloak-js';
import { attachToDebug } from '../../util/debug';
import env from '../../util/env';

const keycloak = new Keycloak({
  clientId: 'px-web',
  realm: env.KEYCLOAK_REALM,
  url: env.KEYCLOAK_URL,
});

attachToDebug('keycloak', keycloak);

export default keycloak;
