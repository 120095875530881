import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material';
import { useDebounce } from '../../util/useDebounce';
import { Box } from '../Box';

export interface SkeletonProps extends MuiSkeletonProps {
  delay?: number;
}

const Skeleton = ({ delay = 500, ...props }: SkeletonProps) => {
  const show = useDebounce({ initial: false, next: true }, delay);

  return (
    <Box
      sx={{
        height: props.height === '100%' ? '100%' : undefined,
        opacity: show ? 1 : 0,
        transition: ({ transitions }) =>
          transitions.create('opacity', {
            duration: transitions.duration.standard,
          }),
      }}
    >
      <MuiSkeleton {...props} />
    </Box>
  );
};

export default Skeleton;
