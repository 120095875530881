import SvgIcon from '../../component/Icon';
import IconType from '../../component/Icon/IconType';
import { NavItem } from '../../component/Navigation/types';
import { Icon } from '../../icon/Icon';
import {
  companiesFullPath,
  equipmentFullPath,
  samAccountFullPath,
  sitesFullPath,
  usersFullPath,
} from '../pathDefinitions';

export const adminNavItems: NavItem[] = [
  {
    to: companiesFullPath,
    icon: <Icon name="companies" size="small" />,
    label: ({ admin }) => admin.sidebar.company,
    access: (permissions) => permissions.some((p) => p.startsWith('entity:')),
  },
  {
    to: sitesFullPath,
    icon: <Icon name="sites" size="small" />,
    label: ({ admin }) => admin.sidebar.site,
    access: (permissions) => permissions.some((p) => p.startsWith('entity:')),
  },
  {
    to: equipmentFullPath,
    icon: <SvgIcon type={IconType.FleetOutlined} size="sm" />,
    label: ({ admin }) => admin.sidebar.equipment,
    access: (permissions) => permissions.some((p) => p.startsWith('entity:')),
  },
  {
    to: usersFullPath,
    icon: <Icon name="users" size="small" />,
    label: ({ admin }) => admin.sidebar.user,
    access: (permissions) => permissions.some((p) => p.startsWith('user:')),
  },
  {
    to: samAccountFullPath,
    icon: <Icon name="sam_account" size="small" />,
    label: ({ admin }) => admin.sidebar.samAccount,
  },
];
