import type { ReactNode } from 'react';
import { Page } from '../../../../component/Layout';
import type { LayoutBreakpoints, LayoutMode } from './LayoutContext';
import { LayoutProvider, useLayout } from './LayoutContext';
import { MultiPanelLayout } from './MultiPanelLayout';
import { StackLayout } from './StackLayout';

export type ContentType = 'main' | 'aside';

interface LayoutProps {
  list: ReactNode;
  main: ReactNode;
  breakpoints: LayoutBreakpoints;
}

export const Layout = ({ list, main, breakpoints }: LayoutProps) => {
  return (
    <LayoutProvider breakpoints={breakpoints}>
      <LayoutRenderer list={list} main={main} />
    </LayoutProvider>
  );
};

export interface LayoutModeProps extends Omit<LayoutProps, 'breakpoints'> {}

const modes: Record<LayoutMode, (props: LayoutModeProps) => JSX.Element> = {
  'multi-panel': MultiPanelLayout,
  stack: StackLayout,
};

const LayoutRenderer = ({ list, main }: LayoutModeProps) => {
  const { layout } = useLayout();
  const LayoutComponent = modes[layout];

  return (
    <Page>
      <LayoutComponent list={list} main={main} />
    </Page>
  );
};
