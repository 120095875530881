import type { ReactNode } from 'react';
import useTranslation from '../../feature/lang/hook';
import type {
  InterpolationValues,
  Translation,
} from '../../feature/lang/types';
import { isTranslatable } from '../../feature/lang/util';

interface TranslateProps {
  text: Translation | ReactNode;
  values?: InterpolationValues;
  count?: number;
}

const Translate = ({ text, values, count }: TranslateProps) => {
  const { translate } = useTranslation();
  return isTranslatable(text) ? (
    <>{translate(text, values, count)}</>
  ) : (
    <>{text}</>
  );
};
export default Translate;
