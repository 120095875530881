import { useEffect, useState } from 'react';
import useDeliveryPoints from '../businessRelations/useDeliveryPoints';
import { usePricesQuery } from '../article/usePriceQuery';
import { PREFETCH_PRICES_DELAY } from './settings';

interface PriceLoaderProps {
  cart?: CartDto;
  onPriceSuccess: (price: PriceAndAvailability, articleId: number) => void;
}

const PriceLoader = ({ cart, onPriceSuccess }: PriceLoaderProps) => {
  const [enabled, setEnabled] = useState(false);
  const { deliveryPoints } = useDeliveryPoints();
  const deliveryPointIds = deliveryPoints.map((dp) => dp.id);
  const fallbackDeliveryPointId =
    deliveryPointIds.length > 0 ? deliveryPointIds[0] : undefined;

  const info =
    cart?.items.map((item) => ({
      articleId: item.itemId,
      deliveryPointId: item.deliveryPointEntityId ?? fallbackDeliveryPointId,
    })) ?? [];

  usePricesQuery(info, { onPriceSuccess, enabled });

  useEffect(() => {
    setTimeout(() => setEnabled(true), PREFETCH_PRICES_DELAY);
  }, []);

  return null;
};

export default PriceLoader;
