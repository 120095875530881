import type { PaletteColor } from '@mui/material';
import { lighten } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import type { AlarmSeverity } from '../feature/alarm/api.types';
import { isKeyOfObject } from '../util/object';

export function useSeverityColor() {
  const theme = useTheme();

  return useCallback(
    (severity: AlarmSeverity): PaletteColor => {
      const severityName = severity.toLowerCase();

      if (isKeyOfObject(severityName, theme.palette.severity)) {
        return theme.palette.severity[severityName];
      }

      return theme.palette.severity.fallback;
    },
    [theme]
  );
}

export function usePaperBackgroundColor(elevation: number = 0) {
  const theme = useTheme();
  const color = theme.palette.background.paper;
  switch (elevation) {
    case 8:
    case 24:
      return theme.palette.mode === 'dark' ? lighten(color, 0.08) : color;
    case 0:
    default:
      return color;
  }
}
