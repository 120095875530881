import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Translate from '../../../component/Translate';
import useTranslation from '../../../feature/lang/hook';
import { Icon } from '../../../icon/Icon';
import useIsSmallScreen from '../../../util/useIsSmallScreen';
import Agreements from './Agreements';
import DataAndSecurity from './DataAndSecurity';
import PersonalInfo from './PersonalInfo';
import Personalization from './Personalization';
import { TabInfo } from './types';

interface ContentComponentProps {
  verticalTabs: boolean;
}

const Root = styled('div', {
  name: 'Content',
  slot: 'Root',
  shouldForwardProp: (propName) => propName !== 'verticalTabs',
})<ContentComponentProps>(({ verticalTabs }) => ({
  display: 'flex',
  flexFlow: verticalTabs ? 'row nowrap' : 'column',
  minHeight: '700px',
}));

const Wrapper = styled('div', {
  name: 'Content',
  slot: 'Wrapper',
  shouldForwardProp: (propName) => propName !== 'verticalTabs',
})<ContentComponentProps>(({ theme, verticalTabs }) => ({
  flex: 1,
  paddingTop: verticalTabs ? 0 : theme.spacing(3),
}));

interface ContentProps {
  tab: TabInfo;
  setTab: (tab: TabInfo) => void;
}

const Content = ({ tab, setTab }: ContentProps) => {
  const isSmallScreen = useIsSmallScreen();
  const verticalTabs = !isSmallScreen;
  const { texts } = useTranslation();

  const handleChange = (_: any, newValue: TabInfo) => {
    setTab(newValue);
  };

  const tabItems = [
    {
      value: TabInfo.PersonalInfo,
      label: texts.user.preferences.personalInfo,
      icon: <Icon name="account_circle" />,
    },
    // The tabs Personalization and DataAndSecurity is already designed and
    // prototyped, but can not be implemented until backend support exists.
    // {
    //   value: TabInfo.Personalization,
    //   label: texts.user.preferences.personalization,
    //   icon: <SettingsIcon />,
    // },
    // {
    //   value: TabInfo.DataAndSecurity,
    //   label: texts.user.preferences.dataAndSecurity,
    //   icon: <LockIcon />,
    // },
    {
      value: TabInfo.Agreements,
      label: texts.user.preferences.agreements,
      icon: <Icon name="card_membership" />,
    },
  ];

  return (
    <Root verticalTabs={verticalTabs}>
      <Tabs
        orientation={verticalTabs ? 'vertical' : 'horizontal'}
        value={tab}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        sx={{ mr: 3 }}
      >
        {tabItems.map(({ value, label, icon }: any, i) => (
          <Tab
            value={value}
            label={
              <div>
                <Translate text={label} />
              </div>
            }
            key={i}
            icon={verticalTabs ? icon : null}
            disableRipple
          />
        ))}
      </Tabs>

      <Wrapper verticalTabs={verticalTabs}>
        <PersonalInfo visible={tab === TabInfo.PersonalInfo} />
        <Personalization visible={tab === TabInfo.Personalization} />
        <DataAndSecurity visible={tab === TabInfo.DataAndSecurity} />
        <Agreements visible={tab === TabInfo.Agreements} />
      </Wrapper>
    </Root>
  );
};

export default Content;
