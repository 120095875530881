import type { SxProps } from '@mui/system';
import { CloseIconButton } from '../../../../component/Button/IconButtons';
import { BackButton as BButton } from '../../../../component/Layout';
import { useSelection } from '../../selection/SelectionContext';
import type { ContentType } from './Layout';
import { useLayout } from './LayoutContext';

const useClose = (where?: ContentType) => {
  const { clearSelection } = useSelection();
  const { aside } = useLayout();

  return () => {
    switch (where) {
      case 'aside':
        aside.close();
        break;
      case 'main':
      default:
        clearSelection();
        break;
    }
  };
};

interface CloseProps {
  where?: ContentType;
  sx?: SxProps;
}

export const BackButton = ({ where, sx }: CloseProps) => {
  const close = useClose(where);

  return <BButton onClick={close} sx={sx} visible />;
};

export const CloseButton = ({ where }: CloseProps) => {
  const close = useClose(where);

  return <CloseIconButton onClick={close} />;
};
