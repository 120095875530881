import { useTranslation } from '../../feature/lang';

export const useUnitLabel = () => {
  const { texts, translate } = useTranslation();
  const unitTexts = texts.common.unit;

  return (unit: string | undefined) => {
    switch (unit) {
      case 'h':
        return translate(unitTexts.time.hour.short);
      case 's':
        return translate(unitTexts.time.second.short);
      case 'timestamp':
      case 'date':
      case 'duration':
        return '';
      default:
        return unit;
    }
  };
};
