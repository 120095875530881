import { IconButton, InputAdornment } from '@mui/material';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ButtonLoading from '../../../component/ButtonLoading';
import { Form, Input } from '../../../component/Form';
import Translate from '../../../component/Translate';
import useTranslation, { useTexts } from '../../../feature/lang/hook';
import usePasswordValidation from '../../../feature/user/usePasswordValidation';
import { Icon } from '../../../icon/Icon';

interface PasswordFormData {
  password: string;
  confirmation: string;
}

interface PasswordFormProps {
  onChangePassword: (data: PasswordFormData) => void;
  children: ReactNode;
}

export const PasswordForm = ({
  onChangePassword,
  children,
}: PasswordFormProps) => {
  return (
    <Form mode="onBlur" onSubmit={onChangePassword}>
      {children}
    </Form>
  );
};

interface PasswordFieldProps {
  user?: UserDto;
}

export const PasswordField = ({ user }: PasswordFieldProps) => {
  const validation = usePasswordValidation(user);

  return <BaseField name="password" validation={validation} />;
};

export const ConfirmationField = () => {
  const { translate, texts } = useTranslation();
  const { watch } = useFormContext();
  const password = watch('password');
  const validationText = texts.admin.user.model.password.validation;

  const validation = {
    validate: (value: any) =>
      password && password !== value
        ? translate(validationText.notMatching)
        : undefined,
  };

  return <BaseField name="confirmation" validation={validation} />;
};

const BaseField = ({ name, validation }: any) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      name={name}
      type={showPassword ? 'text' : 'password'}
      margin="none"
      size="small"
      validation={validation}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              size="large"
            >
              {showPassword ? (
                <Icon name="visibility" size="small" />
              ) : (
                <Icon name="visibility_off" size="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        autocomplete: 'new-password',
      }}
      registerControl
    />
  );
};

interface SubmitChangePasswordButtonProps {
  loading: boolean;
}

export const SubmitChangePasswordButton = ({
  loading,
}: SubmitChangePasswordButtonProps) => {
  const { user } = useTexts();
  return (
    <ButtonLoading
      type="submit"
      variant="contained"
      color="primary"
      loading={loading}
      sx={{ float: 'right' }}
    >
      <Translate text={user.action.changePassword} />
    </ButtonLoading>
  );
};
