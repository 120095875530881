import { toQueryParams } from '../../api/util';
import { get, put } from '../../api/v1';
import { post } from '../../api/v2';
import { alarmDataSchema, alarmDtoSchema } from './api-schema';
import type {
  CreateSignalIntervalDto,
  SignalDefinitionDto,
  SignalIntervalDto,
  SignalIntervalTemplateDto,
  UpdateSignalIntervalDto,
  UpdateSignalIntervalsDto,
} from './api-signal-interval.types.d';
import type { AlarmData, AlarmDto, AlarmStatus } from './api.types';

export const fetchAlarm = (alarmId: string) =>
  get<AlarmDto>(`alarm/${alarmId}`, { schema: alarmDtoSchema });

export interface FetchAlarmsOptions {
  equipment_ids: string[];
  status?: AlarmStatus | AlarmStatus[];
  start_time?: Date;
  end_time?: Date;
  ignore_no_permit?: boolean;
}

export const fetchAlarms = ({
  start_time,
  end_time,
  ...options
}: FetchAlarmsOptions) => {
  const params = {
    ...options,
    start_time: start_time?.toISOString(),
    end_time: end_time?.toISOString(),
  };

  return get<AlarmData>(`alarm${toQueryParams(params)}`, {
    schema: alarmDataSchema,
  });
};

export const fetchSignalIntervals = (equipmentId: string) =>
  get<SignalIntervalDto[]>(`signal/interval${toQueryParams({ equipmentId })}`);

export const fetchSignalDefinitions = () =>
  get<SignalDefinitionDto[]>('signal/definition');

export const createSignalInterval = (intervals: CreateSignalIntervalDto[]) => {
  if (intervals.length === 0) {
    return Promise.resolve(undefined);
  }
  return post<SignalIntervalDto>('signal/interval', intervals);
};

export interface UpdateSignalIntervalOptions {
  id: string;
  data: UpdateSignalIntervalDto;
}

export const updateSignalInterval = ({
  id,
  data,
}: UpdateSignalIntervalOptions) =>
  put<SignalIntervalDto>(`signal/interval/${id}`, data);

export const updateSignalIntervals = (
  intervals: UpdateSignalIntervalsDto[]
) => {
  if (intervals.length === 0) {
    return Promise.resolve();
  }

  return put<void>(`signal/interval`, intervals, {
    responseHandler: () => () => undefined,
  });
};

export const fetchSignalIntervalTemplates = (equipmentId: string) =>
  get<SignalIntervalTemplateDto[]>(
    `signal/interval/template${toQueryParams({ equipmentId })}`
  );
