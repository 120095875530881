import { Select } from '../../../component/Form';
import { Parameter, ParameterList } from '../../../component/ParameterList';
import useTranslation from '../../../feature/lang/hook';
import useLanguages from '../../../feature/lang/useLanguages';
import { ContentContainer } from '../components/ContentContainer';
import { Section } from '../components/Section';
import { sectionSize } from './layout';

interface PersonalizationProps {
  visible: boolean;
}

const Personalization = ({ visible }: PersonalizationProps) => {
  const { options: languageOptions } = useLanguages();

  const {
    texts: {
      user: {
        preferences: { personalization },
      },
      admin,
    },
  } = useTranslation();
  return (
    <ContentContainer visible={visible}>
      <Section>
        <ParameterList
          title={personalization.regionAndLocale}
          keyColumnWidth={sectionSize.keyColumnWidth}
          fullWidth
        >
          <Parameter label={personalization.regionAndLocale.region} value="" />
          <Parameter
            label={personalization.regionAndLocale.language}
            value={
              <Select
                name="language"
                options={languageOptions}
                margin="dense"
                variant="outlined"
              />
            }
          />
          <Parameter
            label={personalization.regionAndLocale.dateFormat}
            value=""
          />
          <Parameter label={personalization.regionAndLocale.units} value="" />
        </ParameterList>
      </Section>
      <Section>
        <ParameterList
          title={personalization.notifications}
          keyColumnWidth={sectionSize.keyColumnWidth}
          fullWidth
        >
          <Parameter label={admin.user.model.emailNotifications} value="" />
          <Parameter label={admin.user.model.pushNotifications} value="" />
          <Parameter label={admin.user.model.smsNotifications} value="" />
        </ParameterList>
      </Section>
      <Section>
        <ParameterList
          title={personalization.updatesAndImportantMessages}
          keyColumnWidth={sectionSize.keyColumnWidth}
          fullWidth
        >
          <Parameter
            label={personalization.updatesAndImportantMessages.receiveMessages}
            value=""
          />
        </ParameterList>
      </Section>
    </ContentContainer>
  );
};

export default Personalization;
