import Typography from '@mui/material/Typography';
import type {
  InterpolationValues,
  Translation,
} from '../../feature/lang/types';
import { Icon } from '../../icon/Icon';
import { Box } from '../Box';
import Tooltip from '../Tooltip';
import Translate from '../Translate';

interface HelpProps {
  text?: Translation;
  values?: InterpolationValues;
  visible?: boolean;
  margin?: 'none' | 'small';
  // Show help text in tooltip
  tooltip?: boolean;
}

function HelpText({
  text,
  values,
  visible = true,
  margin = 'small',
  tooltip = false,
}: HelpProps) {
  const marginValue = margin === 'small' ? 1 : 0;
  if (!text || !visible) {
    return null;
  }

  if (tooltip) {
    return (
      <Box m={marginValue} height="20px">
        <Tooltip title={text} values={values}>
          <Icon name="info" size="small" color="primary" cursor="default" />
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box display="flex" m={marginValue} alignItems="flex-start">
      <Icon name="info" size="small" color="primary" />
      <Typography
        variant="body2"
        sx={{
          ml: 1,
          mt: '3px',
        }}
      >
        <Translate text={text} values={values} />
      </Typography>
    </Box>
  );
}

export default HelpText;
