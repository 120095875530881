import { Typography } from '@mui/material';
import { ReactElement } from 'react';
import useTranslation from '../../feature/lang/hook';
import { headerHeight } from '../Drawer/layout';
import { Grid } from '../Grid';
import Translate from '../Translate';
import { NewVersionInfo } from './HandleNewVersion/NewVersionInfo';
import { isCausedByMissingModule } from './HandleNewVersion/isCausedByMissingModule';

interface DisplayErrorProps {
  error?: any;
  fullscreen?: boolean;
  action?: ReactElement;
}

const DisplayError = ({
  action,
  error,
  fullscreen = false,
}: DisplayErrorProps) => {
  const { texts } = useTranslation();

  if (isCausedByMissingModule(error?.message)) {
    return <NewVersionInfo />;
  }

  return (
    <Grid
      gap={4}
      height={fullscreen ? `calc(100vh - ${headerHeight}rem)` : '100%'}
      columns="1fr"
      rows="1fr [header] auto [message] auto 4fr"
      justifyItems="center"
      alignItems="center"
    >
      <Typography
        variant="h2"
        gutterBottom
        style={{
          gridRowStart: 'header',
        }}
      >
        <Translate text={texts.component.errorBoundary.header} />
      </Typography>
      <div style={{ gridRowStart: 'message' }}>{action}</div>
    </Grid>
  );
};

export default DisplayError;
