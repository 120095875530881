import { attachToDebug } from '../../util/debug';

export function getBuild() {
  if (!window.build || window.build.id.indexOf('#{') !== -1) {
    return {
      id: 'DEV',
      number: 'DEV',
      gitCommitHash: 'DEV',
    };
  }
  return window.build;
}

attachToDebug('build', getBuild());
