import { useMutation } from '@tanstack/react-query';
import useSyncCache from '../../util/useSyncCache';
import { updateLocalizationSetting } from './api';
import type { SettingType } from './api.types';
import { localizationSettingsQueryKey } from './queries';

export const useUpdateLocalizationSetting = (setting: SettingType) => {
  const syncCaches = useSyncCache();

  return useMutation({
    mutationFn: (value: string) => updateLocalizationSetting(setting, value),
    onSuccess: () => syncCaches([localizationSettingsQueryKey]),
  });
};
