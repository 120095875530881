import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { cloneDeep } from 'lodash-es';
import { createContext, lazy, Suspense, useState } from 'react';
import { useToast } from '../../component/Toast';
import { ToastType } from '../../component/Toast/types';
import { gaEvent } from '../../util/googleAnalytics';
import usePriceMissing from '../article/usePriceMissing';
import useCart from '../cart/useCart';
import { useExpandEquipmentIds } from '../equipment/useExpandEquipmentIds';
import useTranslation from '../lang/hook';
import { useCreateOrderMutation } from '../order/orderMutation';
import { useCurrentUser } from '../user/queries';
import { toCreateOrderFormat } from './transform';

const CheckoutDialog = lazy(
  () => import('../../view/ecom/checkout/CheckoutDialog')
);

export enum CheckoutStatus {
  Idle = 'idle',
  CreatingOrder = 'creating-order',
}

const CheckoutContext = createContext<CheckoutContextProps>(
  {} as CheckoutContextProps
);

function getDefaultCheckoutData(data: CheckoutData): CheckoutData {
  return {
    shipmentControl: 'partial',
    criticality: 'normal',
    ...data,
  };
}

const CheckoutProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<CartItemDto[]>([]);
  const [checkoutData, setCheckoutData] = useState<CheckoutData>({});
  const [stepId, setStepId] = useState<number>(0);
  const { mutateAsync: createOrderMutation, isLoading: isCreatingOrder } =
    useCreateOrderMutation();
  const { removeFromCart, selectedDeliveryPoint } = useCart();
  const toast = useToast();
  const { texts } = useTranslation();
  const priceMissing = usePriceMissing(items, selectedDeliveryPoint?.id);
  const user = useCurrentUser();
  const appInsights = useAppInsightsContext();
  const itemsModel = useExpandEquipmentIds(
    items,
    'equipmentId',
    'equipmentName'
  );

  function handleOpen(
    itemsToCheckout: CartItemDto[],
    defaultDeliveryPointId?: string
  ) {
    setItems(cloneDeep(itemsToCheckout));
    setCheckoutData(
      getDefaultCheckoutData({
        deliveryPointId: defaultDeliveryPointId,
        emailRecipients: user.email
          ? [{ value: user.email, deletable: 'false' }]
          : [],
      })
    );
    setStepId(0);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function saveCheckoutData(data?: CheckoutData) {
    if (data) {
      setCheckoutData({ ...checkoutData, ...data });
    }
  }

  function handleNext(data?: CheckoutData) {
    saveCheckoutData(data);

    if (!isLastStep()) {
      setStepId(stepId + 1);
    }
  }

  function handlePrev(data?: CheckoutData) {
    saveCheckoutData(data);

    if (stepId > 0) {
      setStepId(stepId - 1);
    }
  }

  function handleSendOrder() {
    if (priceMissing) {
      handleClose();
      // Last line of defence to prevent user from checking out article with no
      // price
      throw new Error(
        'Unable to checkout as some articles are missing price information.'
      );
    }
    const createOrderData = toCreateOrderFormat(checkoutData, items);
    createOrderMutation(createOrderData, {
      onSuccess: () => {
        removeFromCart(...items.map((item) => item.position));
        handleClose();
        toast({
          message: texts.ecom.checkout.notification.orderSubmitSuccess,
          type: ToastType.Success,
        });
        appInsights.trackEvent({ name: 'Checkout: Send order success ' });
        gaEvent('checkout_send_order_success', { numberOfRows: items.length });
      },
      onError: (error: any) => {
        toast({
          message: texts.ecom.checkout.notification.orderSubmitError,
          values: { errorMessage: error?.message },
          type: ToastType.Error,
        });
        const exception = new Error(`Checkout: Send order`);
        exception.cause = error;
        appInsights.trackException({ exception });
      },
    });
  }

  function getStatus() {
    if (isCreatingOrder) {
      return CheckoutStatus.CreatingOrder;
    }
    return CheckoutStatus.Idle;
  }

  function isLastStep() {
    return stepId === 4;
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkoutData,
        items: itemsModel,
        open,
        status: getStatus(),
        onNext: handleNext,
        onPrev: handlePrev,
        onOpen: handleOpen,
        onClose: handleClose,
        onSendOrder: handleSendOrder,
      }}
    >
      {children}
      <Suspense fallback={<></>}>
        <CheckoutDialog stepId={stepId} />
      </Suspense>
    </CheckoutContext.Provider>
  );
};

export { CheckoutContext, CheckoutProvider };
