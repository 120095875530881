import { useEquipmentQuery } from './queries';
import { isNonEmptyArray } from '../../util/array';

export const useExpandEquipmentIds = (
  array: any[],
  sourceProperty: string,
  targetProperty: string
) => {
  const { data, isLoading } = useEquipmentQuery(undefined, undefined, {
    enabled: isNonEmptyArray(array),
  });

  if (isLoading || !isNonEmptyArray(data)) {
    return array;
  }

  const map = data.reduce((map: Record<string, EquipmentEntity>, eq) => {
    map[eq[sourceProperty]] = eq;

    return map;
  }, {});

  return array.map((item) => ({
    ...item,
    [targetProperty]:
      map[item[sourceProperty]]?.displayName || item[sourceProperty],
  }));
};
