import { Badge } from '@mui/material';
import type { BadgeProps } from '@mui/material';
import { toSxArray } from '../../theme/util';

type CountBubbleMargin = 'default' | 'none';

interface CountBubbleProps extends BadgeProps {
  margin?: CountBubbleMargin;
}

const CountBubble = ({
  margin = 'default',
  color,
  ...badgeProps
}: CountBubbleProps) => {
  if (badgeProps.badgeContent === undefined) {
    return null;
  }

  return (
    <Badge
      {...badgeProps}
      color={color}
      sx={[
        {
          alignItems: 'center',
          '& .MuiBadge-badge': {
            fontWeight: 700,
          },
          '& .MuiBadge-anchorOriginTopRightRectangular': {
            transform: 'none',
            top: 'unset',
            position: 'unset',
          },
        },
        margin === 'default' && {
          ml: 2,
        },
        color === undefined && {
          '& .MuiBadge-badge': (theme) => ({
            backgroundColor:
              theme.palette.grey[theme.palette.mode === 'light' ? 400 : 700],
            color: '#fff',
          }),
        },
        ...toSxArray(badgeProps.sx),
      ]}
    />
  );
};

export default CountBubble;
