import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { SxProps } from '@mui/system';
import type { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Translation } from '../../feature/lang/types';
import { toSxArray } from '../../theme/util';
import Translate from '../Translate';
import Label from './Label';
import { roundedInputSx } from './RoundedInput';
import SelectSkeleton from './SelectSkeleton';
import type { Id, SelectProps } from './types';
import { useValidation } from './useValidation';

const roundedSelectSx: SxProps[] = [
  roundedInputSx,
  {
    '.MuiInputBase-input:focus': {
      backgroundColor: 'unset',
    },
    '.MuiSelect-select': {
      padding: 0,
    },
    '.MuiSelect-icon': {
      right: 12,
    },
  },
];

function Select<T extends Id>({
  margin = 'normal',
  variant = 'filled',
  fullWidth = true,
  label,
  loading,
  hideEmptyOption,
  options,
  renderOption = defaultRenderOption,
  placeholder,
  registerControl,
  validation,
  rounded = false,
  ...rest
}: SelectProps<T>) {
  if (loading) {
    return <SelectSkeleton sx={toSxArray(rest.sx)} />;
  }

  const props = {
    ...rest,
    margin,
    variant,
    fullWidth,
    validation,
    label,
    children: getChildren<T>(hideEmptyOption, options, renderOption),
    select: true,
    InputProps: {
      ...rest.InputProps,
      hiddenLabel: !Boolean(label),
      sx: [
        ...(rounded ? roundedSelectSx : []),
        ...toSxArray(rest.InputProps?.sx),
      ],
    },
    SelectProps: {
      ...rest.SelectProps,
      displayEmpty: true,
      renderValue: (value: any) => {
        const option = options.find((option) => option.id === value);
        const showPlaceholder = placeholder && !option && !label;

        return (
          <Typography
            variant="body2"
            sx={(theme) => ({
              ...(showPlaceholder && { color: theme.palette.text.disabled }),
            })}
            noWrap
          >
            {showPlaceholder && <Translate text={placeholder} />}
            {option && renderOption(option)}
          </Typography>
        );
      },
    },
  };
  if (variant === 'filled') {
    // @ts-ignore
    props.InputProps.disableUnderline = true;
  }

  return registerControl ? (
    <RegisteredControl {...props} />
  ) : (
    <Basic {...props} />
  );
}

export default Select;

function Basic({ label, children, ...rest }: any) {
  return (
    <TextField {...rest} label={label && <Label label={label} />}>
      {children}
    </TextField>
  );
}

function RegisteredControl({
  name,
  validation: validationBase,
  label,
  children,
  ...rest
}: any) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const validation = useValidation(validationBase, { label });
  const error = name && errors[name];
  const helperText = error && (error.message || 'validation error');

  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...rest}
          error={Boolean(error)}
          helperText={helperText}
          label={
            label && (
              <Label label={label} required={Boolean(validation?.required)} />
            )
          }
          {...field}
        >
          {children}
        </TextField>
      )}
      control={control}
      name={name}
      rules={validation}
      defaultValue=""
    />
  );
}

function getChildren<T extends Id>(
  hideEmptyOption: boolean | undefined,
  options: T[],
  renderOption: (option: T) => ReactNode
): ReactNode[] {
  const result = [];

  if (!hideEmptyOption) {
    result.push(
      <MenuItem value="" key="empty">
        <Typography variant="body2">-</Typography>
      </MenuItem>
    );
  }

  result.push(
    ...options.map((option) => (
      <MenuItem value={option.id} key={option.id}>
        <Typography variant="body2">{renderOption(option)}</Typography>
      </MenuItem>
    ))
  );

  return result;
}

function defaultRenderOption(option: any) {
  if (!option) {
    return <></>;
  }
  if (!Object.keys(option).includes('title')) {
    console.warn(
      'The default option renderer for select fields is used. Its using the' +
        ' prop "title" as display value but the options lacks it. Customize' +
        ' the layout of the options by providing a "defaultRenderOption"' +
        ' prop to the select.',
      option
    );
  }
  if (option.title) {
    if (option.title.message) {
      return <Translate text={option.title as Translation} />;
    }
    return <>{option.title}</>;
  }
  return <>{option.id}</>;
}
