import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { excludeProps } from '../../theme/util';
import { isNumber } from '../../util/number';

interface FlexProps {
  alignItems?: string;
  justifyContent?: string;
  flexFlow?: string;
  gap?: number;
  children: ReactNode;
}

export const Flex = styled('div', {
  name: 'Flex',
  slot: 'Container',
  shouldForwardProp: (propName) =>
    excludeProps(propName, [
      'alignItems',
      'flexFlow',
      'justifyContent',
      'gap',
      'sx',
    ]),
})<FlexProps>(
  ({
    theme,
    alignItems = 'center',
    flexFlow = 'row nowrap',
    justifyContent,
    gap = 2,
  }) => ({
    display: 'flex',
    flexFlow,
    alignItems,
    justifyContent,
    gap: theme.spacing(gap),
  })
);

interface FlexItemProps {
  flex?: number;
}

export const FlexItem = styled('div', {
  name: 'Flex',
  slot: 'Item',
  shouldForwardProp: (propName) => excludeProps(propName, ['flex']),
})<FlexItemProps>(({ theme, flex }) => ({
  ...(isNumber(flex) && { flex: theme.spacing(flex) }),
}));
