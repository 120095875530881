import type { ZodError } from 'zod';
import { isDev } from '../util/env';
import { appInsights } from '../feature/insights/appInsights';

function createAIException(error: ZodError) {
  const firstIssueMessage = error.issues.at(0)?.message;
  const exception = new Error(`Incorrect data: ${firstIssueMessage}`);
  exception.cause = error;

  return exception;
}

export function validateData(path: string, options: OptionsStrict) {
  return (d: any) => {
    if (!options.schema) {
      // console.groupCollapsed(
      //   `%cNo schema specified for "${path}"`,
      //   'background: #444; color: #bada55'
      // );
      // console.trace();
      // console.groupEnd();
      return d;
    }

    const data = Array.isArray(d) ? [...d] : [d];
    let error: ZodError | undefined, datum;

    while (data.length > 0 && error === undefined) {
      datum = data.shift();
      const parseResult = options.schema.safeParse(datum);
      if (!parseResult.success) {
        error = parseResult.error;
      }
    }

    if (error) {
      const exception = createAIException(error);
      appInsights.trackException({
        exception,
        properties: { path, data: datum, error },
      });

      if (isDev) {
        console.warn('Incorrect data', {
          error: {
            ...error,
            toString() {
              return 'Error';
            },
          },
          path,
          datum,
        });
      }
    }

    return d;
  };
}
