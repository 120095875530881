import { useEffect } from 'react';
import { getCart } from './query';
import { useCreateCartMutation } from './mutation';
import { createEmptyCart } from './utils';

const useLoadCart = (
  onLoadSuccess: (cart: CartDto) => void,
  enabled: boolean
) => {
  const { mutateAsync: createCartMutation } = useCreateCartMutation();

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const createCart = () => {
      createCartMutation(createEmptyCart()).then(onLoadSuccess);
    };

    getCart()
      .then(onLoadSuccess)
      .catch((error) => {
        if (error.status === 404) {
          createCart();
          return;
        }
        throw error;
      });
  }, [createCartMutation, onLoadSuccess, enabled]);
};

export default useLoadCart;
