import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../util/useStorage';
import { useUpdateLocalizationSetting } from '../userPreference/mutations';
import { useLocalizationSettingsQuery } from '../userPreference/queries';
import { languages } from './config';

export const useStoredLanguage = (defaultLanguage: string) => {
  const [language, setLanguage] = useLocalStorage<string>(
    'settings.language',
    defaultLanguage,
    { deserialize: (data) => data.replace('_', '-') }
  );

  const [isReady, setIsReady] = useState(false);
  const { data, isLoading } = useLocalizationSettingsQuery();
  const { mutateAsync: updateApiLanguage } =
    useUpdateLocalizationSetting('languagetag');
  const languageTag = data?.languageTag;
  const isValid = languages.some((l) => l.id === languageTag);

  useEffect(() => {
    if (!isReady && !isLoading) {
      if (languageTag && isValid) {
        // Use existing stored languagetag from api.
        setLanguage(languageTag);
      } else {
        // No stored languagetag. Save the local value to api.
        updateApiLanguage(language);
      }
      setIsReady(true);
    }
  }, [
    language,
    isReady,
    isValid,
    languageTag,
    isLoading,
    setLanguage,
    updateApiLanguage,
  ]);

  const updateLanguage = (value: string) => {
    setLanguage(value);
    updateApiLanguage(value);
  };

  return { language, updateLanguage, isReady };
};
