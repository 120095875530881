import { Box } from '../../../../component/Box';
import { Skeleton } from '../../../../component/Skeleton';

const EquipmentListSkeleton = () => (
  <Box
    sx={{
      width: 467,
      height: '100%',
      backgroundColor: 'background.paper',
    }}
  >
    <Skeleton sx={{ m: 2, height: 36 }} variant="rectangular" />
  </Box>
);

export default EquipmentListSkeleton;
