import { useToast } from '../../component/Toast';
import { ToastType } from '../../component/Toast/types';
import useTranslation from '../lang/hook';

const useNotifications = () => {
  const { texts: allTexts } = useTranslation();
  const toast = useToast();
  const texts = allTexts.ecom.cart.notification;

  function addNotification(...items: CartItem[]) {
    if (items.length === 0) {
      return;
    }

    if (items.length > 1) {
      toast({
        message: texts.itemsAddedToCartSuccess,
        values: { itemCount: items.length },
        type: ToastType.Success,
      });
    } else {
      const { quantity, displayName: name } = items[0];
      if (quantity && name) {
        toast({
          message: texts.itemAddedToCartSuccess,
          values: { quantity, name },
          type: ToastType.Success,
        });
      } else {
        toast({
          message: texts.itemAddedToCartNoDetailsSuccess,
          type: ToastType.Success,
        });
      }
    }
  }

  function changeDeliveryAddressNotification(
    cart: CartDto,
    positions: number[],
    deliveryPoint?: DeliveryPointOption
  ) {
    const firstItem = getItemByPosition(positions[0], cart);
    const name = firstItem?.itemDetails?.displayName || 'Item';

    if (deliveryPoint) {
      const newDeliveryAddress = deliveryPoint.title;
      const values = { name, newDeliveryAddress };

      toast({
        message: texts.itemUpdateDeliveryAddressSuccess,
        type: ToastType.Success,
        values,
      });
    } else {
      toast({
        message: texts.itemClearDeliveryAddressSuccess,
        values: { name },
        type: ToastType.Success,
      });
    }
  }

  function replaceNotification(fromArticleNo: string, toArticleNo: string) {
    toast({
      message: texts.itemSuperseded,
      values: {
        fromArticleNo,
        toArticleNo,
      },
    });
  }

  function saveErrorNotification() {
    toast({
      message: texts.saveCartError,
      type: ToastType.Error,
    });
  }

  return {
    addNotification,
    changeDeliveryAddressNotification,
    replaceNotification,
    saveErrorNotification,
  };
};

function getItemByPosition(position: number, cart: CartDto) {
  return cart.items.find((item) => item.position === position);
}

export default useNotifications;
