import { useCallback } from 'react';
import { useTranslation } from '../../feature/lang';
import Translate from '../Translate';
import { ExtendedColumn } from './DataProvider';

export const useDecorateColumns = () => {
  const { texts } = useTranslation();

  return useCallback(
    (columnDefinitions: ExtendedColumn[]) =>
      columnDefinitions.map(({ header = () => '', columnName, ...col }) => {
        const Header = col.Header ?? (() => <Translate text={header(texts)} />);
        const ColumnName =
          col.ColumnName ??
          (columnName ? <Translate text={columnName(texts)} /> : Header);

        return {
          ...col,
          Header,
          ColumnName,
        } as ExtendedColumn;
      }),
    [texts]
  );
};
