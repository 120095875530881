import type { ReactNode } from 'react';
import { useTexts } from '../../feature/lang';
import type { Translation } from '../../feature/lang/types';
import type { IconName } from '../../icon/types';
import { RefreshPageButton } from './Actions';
import type { EmptyType } from './Empty';

interface EmptyConfig {
  icon: IconName;
  text: Translation;
  actions?: ReactNode;
}

export const useConfig = (type: EmptyType): EmptyConfig | undefined => {
  const texts = useTexts().component.empty;

  switch (type) {
    case 'no_content':
      return {
        icon: 'no_content',
        text: texts.noContent,
      };
    case 'no_data_list':
      return {
        icon: 'list',
        text: texts.noData,
      };
    case 'no_data_chart':
      return {
        icon: 'chart',
        text: texts.noDataChart,
      };
    case 'invalid_selection':
      return {
        icon: 'list',
        text: texts.invalidSelection,
      };
    case 'error':
      return {
        icon: 'error',
        text: texts.error,
        actions: <RefreshPageButton />,
      };
    case 'warning':
      return {
        icon: 'warning',
        text: texts.warning,
      };
    case 'info':
      return {
        icon: 'info',
        text: texts.info,
      };
    default:
      return undefined;
  }
};
