import { List } from '@mui/material';
import { Icon } from '../../icon/Icon';
import { useRail } from '../Drawer/useRail';
import { ListItemButton } from './ListItemButton';
import { ListItemContent } from './ListItemContent';
import { MainItemContainer } from './MainItemContainer';
import { NavItem } from './types';
import { useNavigation } from './useNavigation';

interface NavigationProps {
  navigation: NavItem[];
}

export const Navigation = ({ navigation: allNavItems }: NavigationProps) => {
  const { navigation, setNewMainRoute } = useNavigation(allNavItems);
  const [rail] = useRail();

  return (
    <List disablePadding>
      {navigation.map((item, i) => {
        const mainItemActive = item.active;

        return (
          <MainItemContainer key={i} active={mainItemActive}>
            <ListItemButton
              to={item.to}
              mainItemActive={mainItemActive}
              selectable={!Boolean(item.subItems?.length)}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setNewMainRoute(item.to);
              }}
            >
              <ListItemContent label={item.label} icon={item.icon} />
              {!mainItemActive && !rail && <Icon name="expand_more" />}
            </ListItemButton>
            {mainItemActive && item.subItems && (
              <List disablePadding>
                {item.subItems.map((subItem, i) => (
                  <ListItemButton
                    key={i}
                    to={subItem.to}
                    mainItemActive={mainItemActive}
                    selectable
                  >
                    <ListItemContent
                      label={subItem.label}
                      icon={subItem.icon}
                      isSubItem
                    />
                  </ListItemButton>
                ))}
              </List>
            )}
          </MainItemContainer>
        );
      })}
    </List>
  );
};
