export function toCreateOrderFormat(
  checkoutData: CheckoutData,
  items: CartItemDto[]
): OrderCreateData {
  if (!checkoutData.deliveryPointId) {
    throw Error('invalid data, no delivery point');
  }
  if (!checkoutData.shipmentControl) {
    throw Error('invalid data, missing shipment control');
  }
  if (!checkoutData.criticality) {
    throw Error('invalid data, missing criticality');
  }
  if (items.length === 0) {
    throw Error('invalid data, no items to checkout');
  }

  const {
    customerOrderRef,
    shipmentControl,
    criticality,
    requestedDeliveryDate,
    deliveryPointId,
  } = checkoutData;

  const emailRecipients = getOrderEmailRecipients(checkoutData);
  const texts: FreeText[] = getOrderTexts(checkoutData);
  const itemsFormatted = getOrderItems(items, requestedDeliveryDate);

  return {
    type: 'order',
    customerOrderRef,
    shipmentControl,
    criticality,
    deliveryPointEntityId: deliveryPointId,
    emailRecipients,
    texts,
    items: itemsFormatted,
  };
}

function getOrderEmailRecipients(checkoutFormData: CheckoutData) {
  return checkoutFormData.emailRecipients?.map((recipient) => recipient.value);
}

function getOrderTexts(checkoutData: CheckoutData): FreeText[] {
  const texts: FreeText[] = [];
  const { orderNotes } = checkoutData;

  if (orderNotes) {
    texts.push({ type: 'external', text: orderNotes });
  }

  return texts;
}

function getOrderItems(items: CartItemDto[], requestedDeliveryDate?: string) {
  return items.map(({ itemId, position, quantity, equipmentId }) => ({
    itemId,
    position,
    quantity,
    requestedDeliveryDate,
    equipmentId,
  }));
}
