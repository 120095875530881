import { useQuery } from '@tanstack/react-query';
import queryClient from '../query/queryClient';
import { priceKey } from './usePriceQuery';

export const useCachedPricesQuery = (
  articleIds: number[],
  deliveryPointId?: string
) =>
  useQuery({
    queryKey: ['cachedPrices', deliveryPointId, articleIds],
    queryFn: () =>
      articleIds
        .map((articleId) =>
          queryClient.getQueryData<PriceAndAvailability>(
            priceKey(articleId, deliveryPointId)
          )
        )
        .filter((price) => price) as PriceAndAvailability[],
  });
