import { usePrices } from './usePrices';

export default function usePriceMissing(
  items: CartItemDto[],
  deliveryPointId?: string
): boolean {
  const { data: prices } = usePrices(
    items.map((item) => item.itemId),
    deliveryPointId
  );

  const itemIdsWithPrice = prices
    .filter(({ price }) => price?.netPrice)
    .map(({ articleId }) => articleId);

  return items.some((item) => !itemIdsWithPrice.includes(item.itemId));
}
