import type { ElementType } from 'react';
import FormatDate from '../../../../component/Date/FormatDate';
import Empty from '../../../../component/Empty';
import { PaneFixedHeader as Pane } from '../../../../component/Layout';
import { Parameter, ParameterList } from '../../../../component/ParameterList';
import { useAlarmByIdQuery } from '../../../../feature/alarm/useAlarmQuery';
import { useTexts } from '../../../../feature/lang';
import { notEmpty } from '../../../../util/array';
import Severity from '../component/Severity';
import { useAlarmDecorator } from '../hooks/useAlarmDecorator';
import type { Alarm } from '../types';

const useAlarm = (alarmId: string) => {
  const { data, isLoading } = useAlarmByIdQuery(alarmId);
  const alarm = useAlarmDecorator([data].filter(notEmpty)).at(0);

  return { alarm, isLoading };
};

export interface AlarmHeaderProps {
  alarm: Alarm | undefined;
  isLoading: boolean;
}

interface DetailsPaneProps {
  alarmId: string;
  HeaderContent: ElementType<AlarmHeaderProps>;
}

export const DetailsPane = ({ alarmId, HeaderContent }: DetailsPaneProps) => {
  const { alarm, isLoading } = useAlarm(alarmId);
  const texts = useTexts().fleet.alarms;

  return (
    <Pane>
      <Pane.Header>
        <HeaderContent alarm={alarm} isLoading={true} />
      </Pane.Header>
      <Pane.Content>
        {alarm || isLoading ? (
          <ParameterList isLoading={isLoading} fullWidth>
            <Parameter label={texts.model.severity}>
              {alarm && (
                <Severity
                  severity={alarm.severity}
                  label={alarm.severityLabel}
                />
              )}
            </Parameter>
            <Parameter label={texts.model.status}>
              {alarm?.statusLabel}
            </Parameter>
            <Parameter label={texts.model.triggered}>
              <FormatDate date={alarm?.eventTriggered} />
            </Parameter>
            <Parameter label={texts.model.name} verticalAlign="top">
              {alarm?.name}
            </Parameter>
            <Parameter label={texts.model.consequences} verticalAlign="top">
              {alarm?.consequences}
            </Parameter>
            <Parameter label={texts.model.actions} verticalAlign="top">
              {alarm?.actions}
            </Parameter>
          </ParameterList>
        ) : (
          <Empty text={texts.details.empty.text} />
        )}
      </Pane.Content>
    </Pane>
  );
};
