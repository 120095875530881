import {
  Skeleton,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isUndefined } from 'lodash-es';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import type { Translation } from '../../feature/lang/types';
import { excludeProps } from '../../theme/util';
import { Box } from '../Box';
import HelpText from '../HelpText';
import Translate from '../Translate';
import context from './context';

interface ParameterTableCellProps {
  disableUnderline?: boolean;
  color?: ParameterColor;
  verticalAlign?: VerticalAlign;
}

const ParameterTableCell = styled(TableCell, {
  name: 'Parameter',
  slot: 'TableCell',
  shouldForwardProp: (prop) =>
    excludeProps(prop, ['disableUnderline', 'color', 'verticalAlign']),
})<ParameterTableCellProps>(
  ({ theme, color = 'default', disableUnderline = false, verticalAlign }) => ({
    '&.MuiTableCell-root': {
      padding: theme.spacing(1),
      ...(disableUnderline && { borderBottom: 'none' }),
      ...(color === 'error' && {
        color: theme.palette.error.main,
        background: theme.palette.error[theme.palette.mode],
        borderColor: theme.palette.error[theme.palette.mode],
      }),
      ...(verticalAlign === 'top' && {
        verticalAlign: 'top',
        paddingTop: theme.spacing(2) + '!important',
      }),
    },
  })
);

type ParameterColor = 'default' | 'error';

type VerticalAlign = 'top';

interface ParameterProps {
  label: Translation;
  align?: TableCellProps['align'];
  underline?: boolean;
  color?: ParameterColor;
  verticalAlign?: VerticalAlign;
  help?: Translation;
}

interface WithValue extends ParameterProps {
  value: ReactNode;
  values?: never;
  children?: never;
}

interface WithChildren extends ParameterProps {
  value?: never;
  values?: never;
  children: ReactNode;
}

interface WithValues extends ParameterProps {
  value?: never;
  values: ReactNode[];
  children?: never;
}

const Parameter = ({
  label,
  help,
  value,
  values,
  align,
  children,
  underline = true,
  color,
  verticalAlign,
}: WithValue | WithValues | WithChildren) => {
  const props = {
    label,
    help,
    align,
    underline,
    color,
    verticalAlign,
  };
  const { emptyValue, isLoading } = useContext(context);

  if (!isUndefined(value)) {
    return <ParameterWithValue value={value} {...props} />;
  }
  if (!isUndefined(children)) {
    return <ParameterWithValue children={children} {...props} />;
  }
  if (values) {
    return <ParameterWithValues values={values} {...props} />;
  }
  if (emptyValue || isLoading) {
    return <ParameterWithValue value={emptyValue} {...props} />;
  }
  return null;
};

const ParameterWithValue = ({
  label,
  help,
  value,
  align,
  children,
  underline,
  color = 'default',
  verticalAlign,
}: WithValue | WithChildren) => {
  const { isLoading, emptyValue } = useContext(context);

  return (
    <TableRow key={`${label}/${value}`}>
      <ParameterTableCell
        verticalAlign={verticalAlign}
        color={color}
        disableUnderline={!underline}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            variant="subtitle2"
            component="span"
            color="textSecondary"
          >
            <Translate text={label} />
          </Typography>
          <HelpText text={help} tooltip />
        </Box>
      </ParameterTableCell>
      <ParameterTableCell
        align={align}
        color={color}
        disableUnderline={!underline}
      >
        {isLoading ? (
          <Skeleton height={16} width="10rem" />
        ) : (
          <Typography variant="body2" component="span">
            {value || children || emptyValue}
          </Typography>
        )}
      </ParameterTableCell>
    </TableRow>
  );
};

const MultiValue = styled('div', { name: 'Parameter', slot: 'MultiValue' })(
  ({ theme }) => ({
    '&:not(:first-of-type)': {
      marginTop: theme.spacing(1),
    },
  })
);

const ParameterWithValues = ({ label, values, underline }: WithValues) => {
  const value = values.map((v, index) => (
    <MultiValue key={index}>{v}</MultiValue>
  ));
  return (
    <ParameterWithValue label={label} value={value} underline={underline} />
  );
};

export default Parameter;
