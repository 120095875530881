import {
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent, ReactNode } from 'react';
import { Fragment } from 'react';
import { ColumnInstance } from 'react-table';
import useIsSmallScreen from '../../util/useIsSmallScreen';
import { Box } from '../Box';
import { TableBodyRow } from './component/TableBodyRow';
import ConfigureColumns from './ConfigureColumns';
import { GroupHeader } from './DataGridGroupHeader';
import { ExtendedColumn } from './DataProvider';
import { Footer } from './Footer';
import GroupBySelector from './GroupBySelector';
import { useDataProvider } from './hook';
import { Pagination } from './Pagination';
import Skeleton from './Skeleton';
import {
  BodyTableCell,
  FooterTableCell,
  HeadTableCell,
} from './StructuredTable/TableCell';
import useRowProps from './StructuredTable/useRowProps';

const Wrapper = styled('div', {
  name: 'DataGrid',
  slot: 'Wrapper',
})({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Root = styled('div', {
  name: 'DataGrid',
  slot: 'Root',
})(({ theme }) => ({
  width: '100%',
  overflowX: 'auto',
  '& .MuiTableCell-head': {
    lineHeight: 2.75,
    backgroundColor: theme.palette.background.paper,
  },
}));

const FilterContainer = styled('div', {
  name: 'DataGrid',
  slot: 'FilterContainer',
})({
  minWidth: '120px',
});

interface DataGridProps {
  // this prop is used to display multiple tables in the same DataProvider
  // i.e grouping a set of data in different containers
  // if you want real filtering of data, use filter functionality in DataProvider
  filter?: (data: any) => boolean;
  enableColumnSelection?: boolean;
  disableGroupSelection?: boolean;
}

export const DataGrid = ({
  disableGroupSelection,
  enableColumnSelection,
  filter = () => true,
}: DataGridProps) => {
  const { footer, grouping, status, tableProps } = useDataProvider();
  const small = useIsSmallScreen();
  const {
    getTableProps,
    gotoPage,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    rows: originalRows,
    state: { pageIndex, pageSize },
  } = tableProps;
  const getRowProps = useRowProps();
  const rows = originalRows.filter(filter);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    gotoPage(newPage);
  };

  if (status === 'loading') {
    return (
      <Root>
        <Skeleton />
      </Root>
    );
  }

  return (
    <Wrapper>
      <Root>
        <MuiTable
          stickyHeader
          {...getTableProps()}
          size={small ? 'small' : 'medium'}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  // @ts-ignore
                  const { onClick, ...headerProps } = getHeaderProps(column);

                  return (
                    <HeadTableCell {...headerProps} title="">
                      {column.canFilter ? (
                        <FilterContainer>
                          {column.render('Filter') as ReactNode}
                        </FilterContainer>
                      ) : null}
                      <div onClick={onClick}>
                        {column.render('Header') as ReactNode}

                        {column.canSort && (
                          <TableSortLabel
                            active={column.isSorted}
                            direction={column.isSortedDesc ? 'desc' : 'asc'}
                          />
                        )}
                      </div>
                    </HeadTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {(page || rows).map((row) => {
              prepareRow(row);
              const { rowProps, isRowClickable, isRowHighlighted } =
                getRowProps(row);
              const { key, ...props }: any = rowProps;

              return (
                <Fragment key={key}>
                  <TableBodyRow
                    {...props}
                    clickable={isRowClickable}
                    highlight={isRowHighlighted}
                  >
                    {row.cells.map((cell) => {
                      const column = cell.column as ExtendedColumn;
                      const cellProps = cell.getCellProps();

                      if (cell.isGrouped) {
                        const groupHeaderProps = { cell, ...cellProps };
                        return column.GroupHeader ? (
                          column.GroupHeader(groupHeaderProps)
                        ) : (
                          <GroupHeader {...groupHeaderProps} />
                        );
                      }
                      if (cell.isPlaceholder) {
                        return <MuiTableCell {...cellProps} />;
                      }
                      if (grouping && row.subRows.length) {
                        return null;
                      }
                      return (
                        <BodyTableCell
                          expandedParent={row.isExpanded && !grouping}
                          selection={cell.column.id === 'selection'}
                          cellNoPadding={column.padding === 'none'}
                          {...cellProps}
                        >
                          {cell.render('Cell') as ReactNode}
                        </BodyTableCell>
                      );
                    })}
                  </TableBodyRow>
                </Fragment>
              );
            })}
          </TableBody>
          {footer && (
            <TableFooter>
              {footerGroups.map((footerGroup) => (
                <TableRow {...footerGroup.getFooterGroupProps()}>
                  {footerGroup.headers.map((column) => (
                    <FooterTableCell {...column.getFooterProps()}>
                      {column.render('Footer') as ReactNode}
                    </FooterTableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          )}
        </MuiTable>
      </Root>
      <Footer
        left={
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {enableColumnSelection && <ConfigureColumns />}
            {grouping && !disableGroupSelection && <GroupBySelector />}
          </Box>
        }
        right={
          Boolean(page) && rows.length > 0 ? (
            <Pagination
              page={pageIndex * pageSize < rows.length ? pageIndex : 0}
              count={rows.length}
              rowsPerPage={pageSize}
              onPageChange={handleChangePage}
            />
          ) : null
        }
      />
    </Wrapper>
  );
};

function getHeaderProps(column: ColumnInstance) {
  if (column.getSortByToggleProps) {
    return column.getHeaderProps(column.getSortByToggleProps());
  }

  return column.getHeaderProps();
}
