import { createContext } from 'react';
import type { QueryStatus } from '@tanstack/react-query';

interface DetailsContext {
  selectedEquipment: EquipmentData[];
  articleId: string;
  isLoading: boolean;
  status: QueryStatus;
  detailsView?: boolean;
}

export default createContext<DetailsContext>({
  status: 'success',
  isLoading: false,
  articleId: '',
  selectedEquipment: [],
});
