import {
  QueriesOptions,
  QueriesResults,
  useQueries,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';

/**
 * A wrapper around react-query useQueries, that limits the number of concurrent
 * http calls to maxConcurrent, to ensure that other queries in the app is not
 * blocked by the browsers limit for maximum concurrent http calls.
 */
export const useConcurrentQueries = <T extends any[]>({
  queries,
  context,
  maxConcurrent = 3,
}: {
  queries: readonly [...QueriesOptions<T>];
  context?: UseQueryOptions['context'];
  maxConcurrent?: number;
}): QueriesResults<T> => {
  const [limitIndex, setLimitIndex] = useState(maxConcurrent);
  const noOfQueries = queries.length;
  const preperedQueries = queries.map(({ enabled, ...query }, i) => ({
    ...query,
    enabled:
      i >= limitIndex - maxConcurrent && i < limitIndex ? enabled : false,
  }));

  const queryResults = useQueries({ queries: preperedQueries, context });

  const noOfActiveQueries = queryResults.filter(
    (result) => result.isFetching
  ).length;
  const noOfFetchedQueries = queryResults.filter(
    (result) => result.isFetched
  ).length;
  const noOfWaitingQueries =
    noOfQueries - noOfFetchedQueries - noOfActiveQueries;

  useEffect(() => {
    if (noOfWaitingQueries > 0 && noOfActiveQueries < maxConcurrent) {
      setLimitIndex(limitIndex + 1);
    }
  }, [noOfActiveQueries, maxConcurrent, noOfWaitingQueries, limitIndex]);

  useEffect(() => {
    if (limitIndex >= noOfQueries && limitIndex > maxConcurrent) {
      setLimitIndex(maxConcurrent);
    }
  }, [noOfQueries, maxConcurrent, limitIndex]);

  // @ts-ignore
  return queryResults;
};
