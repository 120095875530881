import { flatten } from 'lodash-es';
import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { toQueryParams } from '../../api/util';
import { ActiveNavItem, NavItem } from './types';
import { useFilteredNavItems } from './useFilteredNavItems';

const mainRouteHistory: Record<string, string> = {};
const subRouteHistory: Record<string, string> = {};

export const useNavigation = (navItems: NavItem[]) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const filteredNavItems = useFilteredNavItems(navItems);
  const params = new URLSearchParams(search);
  const selected = params.get('selected') ?? '';

  const setNewMainRoute = (newMainRoute: string) =>
    navigate(mainRouteHistory[newMainRoute] ?? newMainRoute);

  useEffect(() => {
    const mainItemMatch = filteredNavItems.find((mainItem) =>
      matchPath(mainItem.to + '/*', pathname)
    );
    if (mainItemMatch) {
      mainRouteHistory[mainItemMatch.to] = pathname + search;
    }

    const subItemMatch = flatten(
      filteredNavItems.map((item) => item.subItems ?? [])
    ).find((subItem) => matchPath(subItem.to + '/*', pathname));
    if (subItemMatch) {
      subRouteHistory[subItemMatch.to] = pathname + search;
    }
  }, [pathname, search, filteredNavItems]);

  const navigation: ActiveNavItem[] = filteredNavItems.map((item) => ({
    ...item,
    active: Boolean(matchPath(item.to + '/*', pathname)),
    subItems: item.subItems?.map((subItem) => {
      const subItemActive = Boolean(matchPath(subItem.to + '/*', pathname));
      const subItemPreviousRoute = subRouteHistory[subItem.to];
      let subItemTo = subItem.to;
      if (item.retainQuery) {
        subItemTo = subItem.to + toQueryParams({ selected });
      } else if (subItemPreviousRoute && !subItemActive) {
        subItemTo = subItemPreviousRoute;
      }

      return {
        ...subItem,
        to: subItemTo,
      };
    }),
  }));

  return { navigation, setNewMainRoute };
};
