import type { ElementType } from 'react';
import { FeatureName, useFeatures } from './index';

interface FeatureWithElementProps {
  name: FeatureName;
  activeComponent: JSX.Element;
  inactiveComponent?: JSX.Element;
  ActiveComponent?: never;
  InactiveComponent?: never;
  render?: never;
}

interface FeatureWithComponentProps {
  name: FeatureName;
  ActiveComponent: ElementType;
  InactiveComponent: ElementType;
  render: (Component: ElementType) => JSX.Element;
  activeComponent?: never;
  inactiveComponent?: never;
}

const Feature = ({
  name,
  activeComponent,
  inactiveComponent = <></>,
  ActiveComponent,
  InactiveComponent,
  render,
}: FeatureWithElementProps | FeatureWithComponentProps) => {
  const features = useFeatures();
  const isActive = features.includes(name);

  if (!render) {
    return isActive ? activeComponent : inactiveComponent;
  }

  return isActive ? render(ActiveComponent) : render(InactiveComponent);
};

export default Feature;
