import { CheckboxProps, Checkbox as MuiCheckbox } from '@mui/material';
import { Icon } from '../../icon/Icon';

const Checkbox = (props: CheckboxProps) => (
  <MuiCheckbox
    checkedIcon={<Icon name="check_box" />}
    icon={<Icon name="check_box_outline_blank" />}
    indeterminateIcon={<Icon name="indeterminate_check_box" />}
    color="default"
    {...props}
  />
);

export default Checkbox;
