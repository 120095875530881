import { Typography } from '@mui/material';
import type { ReactElement } from 'react';
import useTranslation from '../../feature/lang/hook';
import type { Translation } from '../../feature/lang/types';
import Translate from '../Translate';

interface LabelProps {
  label?: Translation | ReactElement;
  required?: boolean;
}

const Label = ({ label, required = false }: LabelProps) => {
  const { texts } = useTranslation();

  if (!label) {
    return null;
  }

  return (
    <Typography variant="body2">
      {required ? (
        <span>
          <Translate text={label} />{' '}
          <Translate text={texts.component.form.label.required} />
        </span>
      ) : (
        <Translate text={label} />
      )}
    </Typography>
  );
};

export default Label;
