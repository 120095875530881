import { isNonEmptyArray } from '../../util/array';
import { attachToDebug } from '../../util/debug';
import { isSampleSubscription, Subscription as TSubscription } from './types';
import { createKey } from './util';

type EventType = 'change';

type EventHandler = (subscriptions: TSubscription[]) => void;

// Never import the class directly, instead import the singleton instance.
// The class exported to make it testable.
export class StreamConfig {
  private subscriptions: Record<string, TSubscription> = {};
  private events: Record<string, EventHandler[]> = {};

  addSubscription(subscription: TSubscription) {
    if (!isValid(subscription)) {
      return () => {};
    }
    const key = createKey(subscription.type);
    this.subscriptions[key] = subscription;
    this.emit('change');

    return () => {
      delete this.subscriptions[key];
      this.emit('change');
    };
  }

  getSubscriptions() {
    return Object.values(this.subscriptions);
  }

  on(event: EventType, handler: EventHandler) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(handler);
  }

  off(event: EventType, handler: EventHandler) {
    if (this.events[event]) {
      const index = this.events[event].findIndex((item) => item === handler);
      this.events[event].splice(index, 1);
    }
  }

  emit(event: EventType) {
    const subscriptions = Object.values(this.subscriptions);
    this.events[event]?.forEach((handler) => handler(subscriptions));
  }
}

function isValid(subscription: TSubscription): boolean {
  if (isSampleSubscription(subscription)) {
    if (!isNonEmptyArray(subscription.equipmentIds)) {
      return false;
    }
    if (!isNonEmptyArray(subscription.signalNames)) {
      return false;
    }
  }

  return true;
}

export const streamConfig = new StreamConfig();
attachToDebug('dataStream', streamConfig);
