import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { excludeProps } from '../../../theme/util';

interface TableBodyRowProps {
  clickable?: boolean;
  highlight?: boolean;
}

export const TableBodyRow = styled(TableRow, {
  name: 'DataGrid',
  slot: 'TableBodyRow',
  shouldForwardProp: (propName) =>
    excludeProps(propName, ['clickable', 'highlight']),
})<TableBodyRowProps>(({ theme, clickable, highlight }) =>
  theme.unstable_sx([
    highlight === true && {
      background: theme.palette.getGrey(20),
    },
    clickable === true && {
      '&:hover': {
        cursor: 'pointer',
        background: theme.palette.getGrey(20),
        transition: '100ms',
      },
    },
  ])
);
