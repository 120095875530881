export interface DataStreamRequest {
  equipmentIds?: string[];
  subscriptions: Subscription[];
}

export type Subscription = SampleSubscription | AlarmCountSubscription;

export type DataStreamResponse = SampleResponse | AlarmCountResponse;

/**
 * Sample
 */
export interface SampleSubscription {
  type: 'sample';
  signalNames: string[];
  includeLatest?: boolean;
  equipmentIds?: string[];
}

export function isSampleSubscription(
  subscription: Subscription
): subscription is SampleSubscription {
  return subscription.type === 'sample';
}

export interface SampleResponse {
  type: 'sample';
  timeStamp: string;
  equipmentId: string;
  signalName: string;
  value: number | null;
}

export function isSampleResponse(
  response: DataStreamResponse
): response is SampleResponse {
  return response.type === 'sample';
}

/**
 * Alarm count
 */
interface AlarmCountSubscription {
  type: 'alarm:count';
  includeLatest?: boolean;
}

interface AlarmCountResponse {
  type: 'alarm.count';
  timeStamp: string;
  equipmentId: string;
  alarmCount: number;
}
