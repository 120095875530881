import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash-es';
import { post } from '../../api/v1';
import { useToast } from '../../component/Toast';
import { useTexts } from '../lang';
import type { FeedbackDto } from './api.types';

function augment(feedback: FeedbackDto): FeedbackDto {
  return merge({}, { context: { url: window.location.href } }, feedback);
}

export const useCreateFeedback = () => {
  const { success } = useToast();
  const texts = useTexts().feature.feedback;

  return useMutation({
    mutationFn: (feedback: FeedbackDto) => post('feedback', augment(feedback)),
    onSuccess: () => {
      success(texts.thankYou);
    },
  });
};
