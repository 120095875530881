import { lazy, Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from '../../../component/Error';
import { Skeleton } from '../../../component/Skeleton';
import { useHasPermission } from '../../../feature/permissions/PermissionsProvider';
import { NotFound } from '../../app/routing/NotFound';
import {
  activitiesPath,
  alarmsPath,
  basicsPath,
  equipmentDataPath,
  equipmentPartsPath,
  insightsPath,
  noticeBoardPath,
} from '../../pathDefinitions';
import DetailsContextProvider from './DetailsContextProvider';
import detailsContext from './detailsContext';

const ActivitiesList = lazy(() => import('../activity/list/ActivitiesList'));
const Alarms = lazy(() => import('../alarms/Alarms'));
const Basics = lazy(() => import('./Basics/Basics'));
const Catalog = lazy(() => import('./Catalog'));
const Data = lazy(() => import('../data/Data'));
const Insights = lazy(() => import('../insights/Insights'));
const NoticeBoard = lazy(() => import('./NoticeBoard'));

interface EquipmentDetailsProps {
  equipmentIds: string[];
  detailsView?: boolean;
}

const Details = ({ equipmentIds, detailsView }: EquipmentDetailsProps) => {
  return (
    <ErrorBoundary>
      <DetailsContextProvider selected={equipmentIds} detailsView={detailsView}>
        <SubRoutes />
      </DetailsContextProvider>
    </ErrorBoundary>
  );
};

export default Details;

function SubRoutes() {
  const { selectedEquipment, isLoading } = useContext(detailsContext);
  const hasEquipmentData = useHasPermission('eqmnt.trends:r');
  const redirectRoute = hasEquipmentData ? equipmentDataPath : basicsPath;

  if (!selectedEquipment) {
    if (!isLoading) {
      return <>something went wrong</>;
    }
  }

  return (
    <Suspense
      fallback={<Skeleton sx={{ m: 2, height: 36 }} variant="rectangular" />}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={redirectRoute + window.location.search} replace />
          }
        />
        <Route
          path={equipmentDataPath}
          element={
            <ErrorBoundary>
              <Data />
            </ErrorBoundary>
          }
        />
        <Route
          path={alarmsPath}
          element={
            <ErrorBoundary>
              <Alarms />
            </ErrorBoundary>
          }
        />
        <Route
          path={insightsPath}
          element={
            <ErrorBoundary>
              <Insights />
            </ErrorBoundary>
          }
        />
        <Route
          path={noticeBoardPath}
          element={
            <ErrorBoundary>
              <NoticeBoard />
            </ErrorBoundary>
          }
        />
        <Route
          path={activitiesPath}
          element={
            <ErrorBoundary>
              <ActivitiesList />
            </ErrorBoundary>
          }
        />
        <Route
          path={basicsPath}
          element={
            <ErrorBoundary>
              <Basics />
            </ErrorBoundary>
          }
        />
        <Route
          path={equipmentPartsPath}
          element={
            <ErrorBoundary>
              <Catalog />
            </ErrorBoundary>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
