import { toSxArray } from '../../theme/util';
import { Skeleton, SkeletonProps } from '../Skeleton';

export interface SelectSkeletonProps extends SkeletonProps {
  hasLabel?: boolean;
}

const SelectSkeleton = ({
  hasLabel = true,
  sx,
  ...rest
}: SelectSkeletonProps) => (
  <Skeleton
    variant="rectangular"
    sx={[
      {
        mt: 1,
        mb: 0.5,
        borderRadius: 1,
        width: '100%',
        height: hasLabel ? '3rem' : '37px',
      },
      ...toSxArray(sx),
    ]}
    {...rest}
  />
);

export default SelectSkeleton;
