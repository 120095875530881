import { useEffect } from 'react';
import useAuth from '../auth/useAuth';
import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { appInsights } from './appInsights';
import { useCurrentUser } from '../user/queries';

const addUserData = (user: UserDto) => (envelope: ITelemetryItem) => {
  if (!envelope?.data) {
    return;
  }
  envelope.data.username = user.username;
  envelope.data.userFirstName = user.firstName;
  envelope.data.userLastName = user.lastName;
};

const DecorateInsightsWithUser = () => {
  const { isAuthenticated } = useAuth();
  const user = useCurrentUser();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    appInsights.setAuthenticatedUserContext(user.id, undefined, true);
    appInsights.addTelemetryInitializer(addUserData(user));
  }, [isAuthenticated, user]);

  return null;
};

export default DecorateInsightsWithUser;
