import { styled } from '@mui/material/styles';
import useQuery from '../../../../util/useQuery';
import useUrlBuilder from '../../../../util/useUrlBuilder';
import { MemorySelectionProvider } from '../../selection/MemorySelectionProvider';
import { Aside } from './aside/Aside';
import type { LayoutModeProps } from './Layout';
import { useLayout } from './LayoutContext';

const Main = styled('main', { name: 'MediumLayoutMain' })({
  height: '100%',
});

export const StackLayout = ({ list, main }: LayoutModeProps): JSX.Element => {
  const { editUrl } = useUrlBuilder();

  return (
    <MemorySelectionProvider
      onSelect={(ids) => {
        editUrl().setParam('selected', ids).push();
      }}
      onClearSelection={() => {
        editUrl().deleteParam('selected').replace();
      }}
    >
      <Pane list={list} main={main} />
    </MemorySelectionProvider>
  );
};

const Pane = ({ list, main }: LayoutModeProps): JSX.Element => {
  const { aside } = useLayout();
  const { query } = useQuery();
  const selected = query.selected ? query.selected.split(',') : [];
  const isSelected = selected.length > 0;

  if (aside.isOpen) {
    return <Aside />;
  }
  if (isSelected) {
    return <Main>{main}</Main>;
  }
  return <>{list}</>;
};
