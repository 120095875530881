import Button from '@mui/material/Button';
import { useState } from 'react';
import { Box } from '../../component/Box';
import ButtonLoading from '../../component/ButtonLoading';
import { useToast } from '../../component/Toast';
import { ToastType } from '../../component/Toast/types';
import Translate from '../../component/Translate';
import { useSignAgreementMutation } from '../../feature/agreement/mutations';
import useAuth from '../../feature/auth/useAuth';
import useTranslation from '../../feature/lang/hook';
import { AgreementDialog } from './dialog/AgreementDialog';
import { RejectAgreement } from './dialog/RejectAgreement';

interface SignUserAgreementDialogProps {
  signActions: SignDocumentAction[];
  onDone: () => void;
}

const SignUserAgreementDialog = ({
  signActions,
  onDone,
}: SignUserAgreementDialogProps) => {
  const { texts } = useTranslation();
  const toast = useToast();
  const { mutateAsync: signAgreement, status: signStatus } =
    useSignAgreementMutation();
  const agreementTypes = signActions.map((action) => action.documentType);
  const [isRejected, setIsRejected] = useState(false);
  const hasRejectOption =
    !isRejected &&
    Boolean(signActions.find((a) => a.documentType === 'TERMS_SERVICE'));
  const { logout } = useAuth();

  const sign = () =>
    Promise.all(signActions.map((action) => signAgreement(action.id)));

  function handleSign() {
    sign()
      .then(onDone)
      .catch((error) =>
        toast({
          message: texts.agreement.notification.signFailed,
          values: { message: error?.message },
          type: ToastType.Error,
        })
      );
  }

  return (
    <AgreementDialog
      open
      agreementTypes={agreementTypes}
      actions={
        <Box display="flex" gap={2} alignItems={'end'}>
          {hasRejectOption && (
            <RejectAgreement onRejected={() => setIsRejected(true)} />
          )}
          {isRejected ? (
            <Button onClick={logout} variant="contained" color="primary">
              <Translate text={texts.user.action.logout} />
            </Button>
          ) : (
            <ButtonLoading
              type="button"
              color="primary"
              variant="contained"
              onClick={handleSign}
              loading={signStatus === 'loading'}
            >
              <Translate text={texts.agreement.action.accept} />
            </ButtonLoading>
          )}
        </Box>
      }
      isRejected={isRejected}
    ></AgreementDialog>
  );
};

export default SignUserAgreementDialog;
