import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import ServerError from '../../api/ServerError';
import { useToast } from '../../component/Toast';
import { gaEvent } from '../../util/googleAnalytics';
import { useTexts } from '../lang';
import {
  changePassword,
  changePasswordForUser,
  createUser,
  softDeleteUser,
  updateUser,
} from './api';
import { useCurrentUser } from './queries';

export const useCreateUserMutation = () => {
  const syncCache = useSyncCache();
  const { success, error } = useToast();
  const { admin } = useTexts();

  return useMutation<UserDto, ServerError, Partial<UserDto>>(createUser, {
    onSuccess: (user) => {
      syncCache(user);
      success(admin.user.invite.notification.success, {
        values: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        },
      });
      gaEvent('user_created', { userRoles: user.roles });
    },
    onError: (e) => {
      error(admin.user.invite.notification.error, {
        values: { message: e.message },
      });
    },
  });
};

export const useChangePasswordMutation = () => {
  return useMutation(changePassword);
};

export const useChangePasswordForUserMutation = () => {
  const { success } = useToast();
  const { admin } = useTexts();

  return useMutation(changePasswordForUser, {
    onSuccess: () => success(admin.user.notification.passwordChanged),
  });
};

const useUpdateUserMutation = () => {
  const getLatestVersion = useLatestVersion();
  const syncCache = useSyncCache();

  return useMutation({
    mutationFn: (user: UserDto) => {
      // Use latest version if request is debounced and contains earlier version
      const version = getLatestVersion(user);

      return updateUser({
        ...user,
        version,
      });
    },
    onSuccess: (user) => syncCache(user),
  });
};

// Updates user. The update operation is queued in case the current operation is
// not finished.
export const useUpdateUser = () => {
  const { mutate, status } = useUpdateUserMutation();
  const [queuedData, setQueuedData] = useState<UserDto | undefined>();

  const update = useCallback(
    (user: UserDto, options?: MutateOptions<UserDto, unknown, UserDto>) => {
      if (status === 'loading') {
        setQueuedData(user);
      } else {
        mutate(user, options);
      }
    },
    [status, setQueuedData, mutate]
  );

  useEffect(() => {
    if (status === 'success' && queuedData) {
      update(queuedData);
      setQueuedData(undefined);
    }
  }, [status, queuedData, setQueuedData, update]);

  return {
    update,
    status,
  };
};

export const useSoftDeleteUserMutation = () => {
  const syncCache = useSyncCache();
  return useMutation(softDeleteUser, { onSuccess: () => syncCache() });
};

function useSyncCache() {
  const queryClient = useQueryClient();
  const { id: currentUserId } = useCurrentUser();

  return (user?: UserDto) => {
    queryClient.invalidateQueries(['users']);
    queryClient.invalidateQueries(['companies']);

    if (user) {
      queryClient.invalidateQueries(['user', user.id]);
      const isCurrentUserUpdated = user.id === currentUserId;
      if (isCurrentUserUpdated) {
        queryClient.setQueryData(['currentUser'], user);
      }
    }
  };
}

function useLatestVersion() {
  const queryClient = useQueryClient();

  return (user: UserDto) => {
    const queryData = queryClient.getQueryData<UserDto>(['user', user.id]);
    return queryData?.version ?? user.version;
  };
}
