import { Toolbar } from '../../../../../component/Layout';
import { Skeleton } from '../../../../../component/Skeleton';
import type { AlarmHeaderProps } from '../../../alarms/details/DetailsPane';
import { DetailsPane } from '../../../alarms/details/DetailsPane';
import { BackButton, CloseButton } from '../Buttons';
import { useLayout } from '../LayoutContext';
import type { AsideProps } from './Aside';

export const AlarmAside = ({ id }: AsideProps) => (
  <DetailsPane alarmId={id} HeaderContent={HeaderContent} />
);

const HeaderContent = ({ alarm, isLoading }: AlarmHeaderProps) => {
  const { layout } = useLayout();

  return (
    <>
      {layout === 'stack' && <BackButton where="aside" />}
      <Toolbar
        header={
          isLoading ? (
            <Skeleton width={300} height={36} variant="rectangular" />
          ) : undefined
        }
        title={alarm?.name}
        actionButtons={
          layout === 'multi-panel' ? <CloseButton where="aside" /> : null
        }
      />
    </>
  );
};
