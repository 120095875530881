import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { ONE_HOUR, TEN_MINUTES } from '../../util/time';
import queryClient from '../query/queryClient';
import { fetchPrices } from './api';

export const priceKey = (articleId: number, deliveryPointId?: string) => [
  'price',
  articleId,
  deliveryPointId,
];

export const pricesKey = (articleIds: number[], deliveryPointId?: string) => [
  'prices',
  articleIds,
  deliveryPointId,
];

const queryOptions = (articleId: number, deliveryPointId?: string) => {
  if (!deliveryPointId) {
    return {};
  }
  return {
    queryKey: pricesKey([articleId], deliveryPointId),
    queryFn: (): Promise<PriceAndAvailability[]> =>
      fetchPrices([articleId], deliveryPointId),
    // Prevents prices from being fetched too often
    staleTime: TEN_MINUTES,
    cacheTime: ONE_HOUR,
  };
};

interface UsePricesQueryOptions {
  enabled?: boolean;
  onPriceSuccess?: (price: PriceAndAvailability, articleId: number) => void;
}

type PriceQueryInfo = {
  articleId: number;
  deliveryPointId?: string;
};

/**
 * Queries prices for a list of article id and delivery point id combinations.
 *
 * @param info List of article id and delivery point id combinations.
 * @param options Query options
 */
export function usePricesQuery(
  info: PriceQueryInfo[],
  options: UsePricesQueryOptions = {}
) {
  const { enabled, onPriceSuccess = () => {} } = options;

  const queries: UseQueryOptions[] = info
    .filter(({ deliveryPointId }) => deliveryPointId !== undefined)
    .map(({ articleId, deliveryPointId }) => ({
      ...queryOptions(articleId, deliveryPointId),
      onSuccess: (data: any) => {
        const price = data?.at(0) as PriceAndAvailability;
        onPriceSuccess(price, articleId);
        queryClient.setQueryData(
          priceKey(price.articleId, deliveryPointId),
          price
        );
      },
      enabled,
    }));

  return useQueries({ queries });
}
