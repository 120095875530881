import { Row, TableRowProps } from 'react-table';
import { useDataProvider } from '../hook';
import { DataProviderContext } from '../context';

interface IsClickableOptions
  extends Pick<DataProviderContext, 'rowClickable' | 'onRowClick'> {
  row: Row<any>;
}

function isClickable({ row, rowClickable, onRowClick }: IsClickableOptions) {
  if (row.isGrouped && row.subRows.length) {
    return true;
  }
  if (rowClickable) {
    return rowClickable(row);
  }
  if (Boolean(onRowClick)) {
    return true;
  }

  return false;
}

interface IsHighlightedOptions
  extends Pick<DataProviderContext, 'highlightRowId'> {
  row: Row<any>;
}

function isHighlighted({ row, highlightRowId }: IsHighlightedOptions) {
  return (row.original && highlightRowId?.(row.original)) ?? false;
}

interface TableRowPropsExtended extends TableRowProps {
  onClick?: (row: Row<{}>) => void;
}

interface UseRowPropsResult {
  rowProps: any;
  isRowClickable: boolean;
  isRowHighlighted: boolean;
}

const useRowProps = () => {
  const { rowClickable, onRowClick, highlightRowId } = useDataProvider();

  return (row: Row<any>): UseRowPropsResult => {
    const props: TableRowProps = row.getRowProps();
    const isRowClickable = isClickable({ row, rowClickable, onRowClick });
    const isRowHighlighted = isHighlighted({ row, highlightRowId });

    const rowProps: TableRowPropsExtended = {
      ...props,
      onClick: isRowClickable
        ? () => {
            if (row.isGrouped && row.subRows.length) {
              row.toggleRowExpanded();
            } else {
              onRowClick?.(row);
            }
          }
        : undefined,
    };

    return {
      rowProps,
      isRowClickable,
      isRowHighlighted,
    };
  };
};

export default useRowProps;
