import type { SxProps } from '@mui/system';
import type { ReactNode } from 'react';
import { toSxArray } from '../../theme/util';
import { appBarHeight } from '../AppBar/AppBar';
import { Box } from '../Box';

interface PageProps {
  children: ReactNode;
  className?: string;
  sx?: SxProps;
}

const Page = ({ children, className, sx }: PageProps) => {
  return (
    <Box
      className={className}
      sx={[
        {
          height: `calc(100vh - ${appBarHeight}px)`,
        },
        ...toSxArray(sx),
      ]}
      children={children}
    />
  );
};

export default Page;
