import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Skeleton } from '../../component/Skeleton';
import { activitiesPath, noticeBoardPath } from '../pathDefinitions';
import FleetOverview from './FleetOverview';

const ActivityRoutes = lazy(() => import('./activity/ActivityRoutes'));
const ClaimDetails = lazy(() =>
  import('./basics/claims/details/ClaimDetails').then((module) => ({
    default: module.ClaimDetails,
  }))
);
const NotificationDetails = lazy(
  () => import('./pages/notifications/NotificationDetails')
);

export const notificationIdParam = 'notificationId';
export const activityIdParam = 'activityId';
export const claimIdParam = 'claimId';
export const claimPath = 'claim';

export default () => (
  <Suspense
    fallback={<Skeleton sx={{ m: 2, height: 36 }} variant="rectangular" />}
  >
    <Routes>
      <Route path="/*" element={<FleetOverview />} />
      <Route
        path={`${noticeBoardPath}/:${notificationIdParam}`}
        element={<NotificationDetails />}
      />
      <Route
        path={`${activitiesPath}/:${activityIdParam}/*`}
        element={<ActivityRoutes />}
      />
      <Route
        path={`${claimPath}/:${claimIdParam}`}
        element={<ClaimDetails />}
      />
    </Routes>
  </Suspense>
);
