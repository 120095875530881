import { Icon } from '../../icon/Icon';
import { appBarHeight } from '../AppBar/AppBar';
import { Box } from '../Box';
import IconButton from '../Button/IconButton';
import SamLogo from '../Logo/SamLogo';

const MobileDrawerHeader = ({ close }: { close: () => void }) => (
  <Box
    sx={(theme) => ({
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1, 0, 2),
      height: `${appBarHeight}px`,
    })}
  >
    <SamLogo />
    <Box flexGrow="1" />
    <IconButton onClick={close} size="small">
      <Icon name="arrow_back" size="small" />
    </IconButton>
  </Box>
);

export default MobileDrawerHeader;
