import { useMemo } from 'react';
import { useAlarmPropTexts } from '../../../../feature/alarm';
import type { AlarmDto } from '../../../../feature/alarm/api.types';
import dataBuilder from '../../../../util/dataBuilder';
import type { Alarm } from '../types';
import { useAlarmEquipment } from './useAlarmEquipment';

export const useAlarmDecorator = (dtos: AlarmDto[] = []): Alarm[] => {
  const { selectedEquipment } = useAlarmEquipment();
  const { getStatusName } = useAlarmPropTexts();

  return useMemo(
    () =>
      dataBuilder<AlarmDto, Alarm>(dtos)
        .decorate({
          withData: selectedEquipment,
          locator: (alarm, equipment) => alarm.equipmentId === equipment.id,
          from: 'displayName',
          to: 'equipmentDisplayName',
        })
        .transform((alarm) => ({
          ...alarm,
          statusLabel: getStatusName(alarm.status),
        }))
        .build(),
    [dtos, getStatusName, selectedEquipment]
  );
};
