import { uniqueValues } from '../../util/array';

/**
 * Creates a query clause which is used as query parameter in various API
 * endpoints. "id:1234" and "type:account" are examples of query clauses.
 *
 * Example searching for all accounts:
 * /api/v2/entity?query=type:account
 *
 * All accounts in region Europe (id 1234):
 * /api/v2/entity?query=type:account%20ancestor:1234
 *
 * @param criteria Criteria to convert into query clause
 */
export function createQueryClause(
  criteria: string | Criteria | undefined
): string {
  if (!criteria) {
    return '';
  }
  if (typeof criteria === 'string') {
    return clause.fromString(criteria);
  }
  return clause.fromCriteria(criteria);
}

const clause = {
  fromCriteria(criteria: Criteria) {
    return Object.entries(criteria)
      .reduce((arr: string[], [key, value]) => {
        if (value) {
          arr.push(...clause.fromKeyValue(value, key));
        }
        return arr;
      }, [])
      .join(' ');
  },
  fromString(criteria: string) {
    return criteria;
  },
  fromKeyValue(value: string | string[], key: string) {
    if (value === undefined) {
      return [];
    }
    if (key === 'query') {
      const [query] = getValues(value);
      return [query];
    }
    if (Array.isArray(value)) {
      return getValues(value).map((v) => `${key}:${v}`);
    }
    return [`${key}:${value}`];
  },
};

function getValues(value: string | string[]) {
  return uniqueValues(value).filter((value) => value !== undefined);
}
