import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  styled,
} from '@mui/material';
import { isDev } from '../../util/env';
import Tooltip from '../Tooltip';

const StyledChip = styled(MuiChip, {
  name: 'Chip',
  shouldForwardProp: (propName) => propName !== 'vertical',
})<ChipProps>(({ theme, vertical }) => ({
  fontWeight: 700,
  textTransform: 'uppercase',
  transform: 'rotate(0)',
  transformOrigin: '30% 30%',
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  ...(vertical && {
    transform: 'rotate(-90deg)',
  }),
}));

interface ChipProps extends MuiChipProps {
  tooltip?: string;
  vertical?: boolean;
}

const Chip = ({ tooltip, vertical = false, ...props }: ChipProps) => {
  const chip = (
    <StyledChip color="primary" size="small" vertical={vertical} {...props} />
  );

  return (
    <span>
      {tooltip ? (
        <Tooltip title={tooltip} disableInteractive>
          {chip}
        </Tooltip>
      ) : (
        <>{chip}</>
      )}
    </span>
  );
};

export default Chip;

export const DevChip = ({ ...props }: ChipProps) => {
  return (
    <>
      {isDev && (
        <Chip
          label="DEV"
          tooltip="This is a development environment"
          sx={{
            ...(props.vertical && { minWidth: 50 }),
          }}
          {...props}
        />
      )}
    </>
  );
};
