import { SxProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Icon } from '../../icon/Icon';

interface CloseIconButtonProps {
  onClick?: () => void;
  sx?: SxProps;
}

export function CloseIconButton({ onClick, sx }: CloseIconButtonProps) {
  return (
    <IconButton onClick={onClick} size="medium" sx={sx}>
      <Icon name="close" />
    </IconButton>
  );
}
