import { get, post } from '../../api/apiClient';
import { post as postV2 } from '../../api/v2';

export const fetchAgreement = (type: AgreementType) => {
  return get<AgreementDocument>(`agreement/document/${type}`, {
    // Agreement service does not wrap result in data property
    selector: (json) => json,
  });
};

export const signAgreement = (...documentIds: string[]) => {
  return post<{}>(
    'agreement/signature',
    getSignAgreementPostBody(...documentIds)
  );
};

function getSignAgreementPostBody(...documentIds: string[]) {
  return {
    documents: documentIds,
  };
}

export const fetchSignedAgreements = () => {
  return get<SignedAgreement[]>('agreement/signature', {
    selector: (json) => json.documents,
  });
};

export const rejectAgreement = () => {
  return postV2(
    'user/sign/account?accountState=terms_conditions_rejected',
    undefined
  );
};
