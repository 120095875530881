import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
  darken,
  lighten,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import type {
  InterpolationValues,
  Translation,
} from '../../feature/lang/types';
import { Icon } from '../../icon/Icon';
import { subtract } from '../../theme/util';
import CountBubble from '../CountBubble';
import HelpText from '../HelpText';
import StopPropagation from '../StopPropagation';
import Translate from '../Translate';
import { useAccordion } from './AccordionProvider';

const ExpandIcon = styled(
  (props: any) => <Icon name="expand_more" {...props} />,
  { name: 'AccordionSummary', slot: 'ExpandIcon' }
)({ color: 'inherit' });

interface AccordionSummaryRootProps {
  stickyOffset: number;
}

const AccordionSummaryRoot = styled(MuiAccordionSummary, {
  name: 'AccordionSummary',
  slot: 'Root',
  shouldForwardProp: (propName) => propName !== 'stickyOffset',
})<AccordionSummaryRootProps>(
  ({
    theme: { shape, transitions, palette, spacing, zIndex },
    stickyOffset,
  }) => ({
    margin: 0,
    minHeight: '3rem',
    borderRadius: subtract(shape.borderRadius, 1) + 'px',
    transition: transitions.create('background-color', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shortest,
    }),
    backgroundColor: palette.background.paper,
    position: 'sticky',
    top: stickyOffset,
    zIndex: zIndex.mobileStepper - 2,

    '&:hover': {
      backgroundColor:
        palette.mode === 'dark'
          ? lighten(palette.background.paper, palette.action.hoverOpacity)
          : darken(palette.background.paper, palette.action.hoverOpacity),
    },
    '&.Mui-expanded': {
      minHeight: '3rem',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      padding: spacing(1.5),
      marginRight: spacing(-1.5),
      color: 'inherit',
    },
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      gap: spacing(2),
      alignItems: 'center',
      minWidth: 0,
      margin: 0,
      '&.Mui-expanded': {
        margin: 0,
      },
    },
  })
);

const Spacer = styled('div', { name: 'AccordionSummary', slot: 'Spacer' })(
  ({ theme }) => ({
    flexGrow: 1,
    minWidth: theme.spacing(2),
  })
);

interface ActionsProps {
  showActions: boolean;
}

const Actions = styled('div', {
  name: 'AccordionSummary',
  slot: 'Actions',
  shouldForwardProp: (propName) => propName !== 'showActions',
})<ActionsProps>(({ theme, showActions }) =>
  theme.unstable_sx([
    {
      display: 'flex',
      alignItems: 'center',
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shortest,
      }),
      gap: 1.5,
    },
    showActions === false && {
      opacity: 0,
      pointerEvents: 'none',
    },
  ])
);

export interface AccordionSummaryProps extends MuiAccordionSummaryProps {
  header?: Translation;
  headerValues?: InterpolationValues;
  actions?: ReactNode;
  count?: number;
  badgeColor?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  help?: Translation;
  chip?: ReactNode;
  stickyOffset?: number;
}

const AccordionSummary = ({
  header,
  headerValues,
  actions,
  count,
  badgeColor = 'primary',
  help,
  chip,
  stickyOffset = 0,
  ...rest
}: AccordionSummaryProps) => {
  const { expanded } = useAccordion();

  // Checking if expanded !== false as expanded === undefined value indicates
  // that accordion is running in uncontrolled mode.
  const showActions = expanded !== false;

  return (
    <AccordionSummaryRoot
      expandIcon={<ExpandIcon />}
      stickyOffset={stickyOffset}
      {...rest}
    >
      {header && (
        <Typography variant="h5" noWrap>
          <Translate text={header} values={headerValues} />
        </Typography>
      )}
      {Boolean(count) && (
        <CountBubble badgeContent={count} color={badgeColor} margin="none" />
      )}
      {help && <HelpText text={help} tooltip margin="none" />}
      {chip}
      <Spacer />
      <StopPropagation>
        <Actions showActions={showActions}>{actions}</Actions>
      </StopPropagation>
    </AccordionSummaryRoot>
  );
};

export default AccordionSummary;
