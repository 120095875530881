import { useState } from 'react';
import IconButton from '../../../component/Button/IconButton';
import Divider from '../../../component/Divider';
import { Menu, MenuItem } from '../../../component/Menu';
import Popper from '../../../component/Popper';
import AccountDialog from '../../../feature/account/AccountDialog';
import { useAccountSelector } from '../../../feature/account/AccountProvider';
import { Feature, FeatureToggleDialog } from '../../../feature/features';
import FeedbackDialog from '../../../feature/feedback/FeedbackDialog';
import { useTexts, useTranslation } from '../../../feature/lang';
import Access from '../../../feature/permissions/Access';
import IsRootRealmUser, {
  useIsRootRealmUser,
} from '../../../feature/permissions/IsRootRealmUser';
import { useHasPermission } from '../../../feature/permissions/PermissionsProvider';
import { Icon } from '../../../icon/Icon';

interface WithDialog {
  onOpen: (dialog: string) => void;
}

const AdminActions = () => {
  const [open, setOpen] = useState('');

  const onOpen = (dialog: string) => setOpen(dialog);
  const onClose = () => setOpen('');

  const hasFeatureTogglePermit = useHasPermission('feattgl:r');
  const isRootRealmUser = useIsRootRealmUser();

  if (!hasFeatureTogglePermit && !isRootRealmUser) {
    return null;
  }

  // When feature flag "feedback" is off-boarded the admin actions should always be visible.
  // This requires removal of guard around line 31-33 in this file.
  return (
    <>
      <Popper
        Content={({ onClose }) => (
          <Menu onClose={onClose}>
            <FeaturesMenuItem onOpen={onOpen} />
            <AccountMenuItem onOpen={onOpen} />
            <ToggleLanguageKeysMenuItem />
            <Feature
              name="feedback"
              activeComponent={
                <>
                  <Divider />
                  <FeedbackMenuItem onOpen={onOpen} />
                </>
              }
            />
          </Menu>
        )}
        Trigger={({ onClick }) => (
          <IconButton
            onClick={onClick}
            color="inherit"
            size="large"
            sx={{ ml: 0.5, mr: -0.5 }}
          >
            <Icon name="more_vert" />
          </IconButton>
        )}
        placement="top-start"
      />
      <FeatureToggleDialog open={open === 'feature'} onClose={onClose} />
      <AccountDialog open={open === 'account'} onClose={onClose} />
      <FeedbackDialog open={open === 'feedback'} onClose={onClose} />
    </>
  );
};

export default AdminActions;

const FeaturesMenuItem = ({ onOpen }: WithDialog) => {
  const { app } = useTexts();

  return (
    <Access requiredPermit="feattgl:r">
      <MenuItem
        label={app.features.action.openDialog}
        icon={<Icon name="build" />}
        onClick={() => onOpen('feature')}
      />
    </Access>
  );
};

const AccountMenuItem = ({ onOpen }: WithDialog) => {
  const { user } = useTexts();
  const { account } = useAccountSelector();

  return (
    <IsRootRealmUser>
      <MenuItem
        label={user.action.openAccountSelection}
        icon={<Icon name="users" />}
        onClick={() => onOpen('account')}
        disabled={Boolean(account)}
      />
    </IsRootRealmUser>
  );
};

const ToggleLanguageKeysMenuItem = () => {
  const { showKeys, setShowKeys } = useTranslation();

  // Requires permit "feattgl:r" for now as no other applicable permit exists
  return (
    <Access requiredPermit="feattgl:r">
      <MenuItem
        label="Toggle language keys"
        icon={showKeys ? <Icon name="key_off" /> : <Icon name="key" />}
        onClick={() => setShowKeys(!showKeys)}
      />
    </Access>
  );
};

const FeedbackMenuItem = ({ onOpen }: WithDialog) => {
  const texts = useTexts().feature.feedback;

  return (
    <MenuItem
      label={texts.action.openDialog}
      icon={<Icon name="comment" />}
      onClick={() => onOpen('feedback')}
    />
  );
};
