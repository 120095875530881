import { useState } from 'react';
import { useTexts } from '../lang';
import Translate from '../../component/Translate';
import useFeatures from './useFeatures';
import { FeatureToggleDialog } from './index';
import Chip from '../../component/Chip/Chip';

interface FeaturesActiveChipProps {
  vertical?: boolean;
}

const FeaturesActiveChip = ({ vertical = false }: FeaturesActiveChipProps) => {
  const [open, setOpen] = useState(false);
  const texts = useTexts();
  const features = useFeatures();

  return (
    <>
      {features.activeCount > 0 && (
        <Chip
          label={<Translate text={texts.app.features.activeNotice} />}
          tooltip="Beta features activated"
          vertical={vertical}
          onClick={() => setOpen(true)}
          clickable
          sx={{
            ...(vertical && { minWidth: 50 }),
          }}
        />
      )}
      <FeatureToggleDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default FeaturesActiveChip;
