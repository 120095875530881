import { useTexts } from '../../feature/lang';
import { Select } from '../Form';
import { useDataProvider } from './hook';

const GroupBySelector = () => {
  const { tableProps, groupBy } = useDataProvider();
  const texts = useTexts().component.dataGrid.groupBy;

  return (
    <Select
      value={tableProps.state.groupBy[0] || ''}
      options={tableProps.columns.map((col) => ({
        id: col.id,
        title: col.render('Header'),
      }))}
      name="groupBy"
      onChange={(e) => groupBy(e.target.value)}
      margin="none"
      sx={{
        width: 'unset',
        minWidth: '150px',
      }}
      placeholder={texts.selector}
      rounded
    />
  );
};

export default GroupBySelector;
