import { styled, Theme, useTheme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { excludeProps, getColor } from '../../theme/util';
import IconType from './IconType';

interface IconProps {
  type: IconType;
  size?: IconSize;
  color?: IconColor;
  sx?: SxProps<Theme>;
}

interface RootProps {
  size: IconSize;
  color: string;
}
const Root = styled('span', {
  name: 'Icon',
  slot: 'Root',
  shouldForwardProp: (propName) => excludeProps(propName, ['size', 'color']),
})<RootProps>(({ size, color }) => ({
  lineHeight: 0,
  svg: {
    '& path': {
      fill: color,
    },
    '& path[stroke]': {
      stroke: color,
    },
    '& rect': {
      stroke: color,
    },
    ...(size === 'sm' && {
      width: '20px',
      minWidth: '20px',
      height: '20px',
      minHeight: '20px',
    }),
    ...(size === 'md' && {
      width: '24px',
      minWidth: '24px',
      height: '24px',
      minHeight: '24px',
    }),
    ...(size === 'lg' && {
      width: '32px',
      minWidth: '32px',
      height: '32px',
      minHeight: '32px',
    }),
    ...(size === 'full' && {
      width: '100%',
      minWidth: '100%',
      height: '100%',
      minHeight: '100%',
    }),
  },
}));

const Icon = ({ type, size = 'md', color, sx }: IconProps) => {
  const theme = useTheme();
  const c = getColor(theme, color);
  const { IconComponent } = type;

  return (
    <Root color={c} size={size} sx={sx}>
      <IconComponent />
    </Root>
  );
};

export default Icon;
