import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material';
import { toSxArray } from '../../theme/util';
import useIsSmallScreen from '../../util/useIsSmallScreen';
import { CloseIconButton } from '../Button/IconButtons';

type DialogHeight = 'md' | 'lg';

export interface DialogProps extends MuiDialogProps {
  height?: DialogHeight;
  onCloseIcon?: () => void;
}

const presetHeights = {
  md: '800px',
  lg: '1200px',
};

const Dialog = ({
  children,
  PaperProps,
  height,
  onCloseIcon,
  ...rest
}: DialogProps) => {
  const isSmallScreen = useIsSmallScreen();
  const { sx } = PaperProps ?? {};
  const heightSx = height
    ? { height: presetHeights[height], maxHeight: '100%' }
    : {};

  return (
    <MuiDialog
      fullScreen={isSmallScreen}
      hideBackdrop={isSmallScreen}
      maxWidth="sm"
      fullWidth
      {...rest}
      PaperProps={{
        ...PaperProps,
        sx: [
          {
            ...heightSx,
            borderRadius: 3,
          },
          ...toSxArray(sx),
        ],
      }}
    >
      {onCloseIcon && (
        <CloseIconButton
          sx={{ position: 'absolute', right: 8, top: 8 }}
          onClick={onCloseIcon}
        />
      )}
      {children}
    </MuiDialog>
  );
};

export default Dialog;
