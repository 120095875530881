import { CancelButton, OkButton } from '../../component/Button/Buttons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '../../component/Dialog';
import { Checkbox, Form, Input, Select } from '../../component/Form';
import { Parameter, ParameterList } from '../../component/ParameterList';
import { useTexts, useTranslation } from '../lang';
import type { FeedbackDto, FeedbackType } from './api.types';
import { useCreateFeedback } from './mutations';

interface FeedbackFormData {
  type: FeedbackType;
  feedback: string;
  contactApproval?: boolean;
}

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
}

const FeedbackDialog = ({ open, onClose }: FeedbackDialogProps) => {
  const texts = useTexts().feature.feedback;
  const typeOptions = useTypeOptions();
  const { mutateAsync, isLoading } = useCreateFeedback();

  const save = async (formData: FeedbackFormData) => {
    const feedback = transform(formData);
    await mutateAsync(feedback);
    onClose();
  };

  return (
    <Form onSubmit={save}>
      <Dialog open={open} onClose={onClose} disablePortal>
        <DialogTitle title={texts.dialog.header} />
        <DialogContent>
          <ParameterList fullWidth>
            <Parameter label={texts.model.type}>
              <Select
                name="type"
                options={typeOptions}
                margin="none"
                size="small"
                validation={{ required: true }}
                registerControl
              />
            </Parameter>
            <Parameter label={texts.model.feedback} verticalAlign="top">
              <Input
                name="feedback"
                margin="none"
                validation={{ required: true }}
                multiline
                rows={4}
                registerControl
              />
            </Parameter>
            <Parameter label={texts.model.contactApproval}>
              <Checkbox name="contactApproval" registerControl />
            </Parameter>
          </ParameterList>
        </DialogContent>
        <DialogActions
          right={
            <>
              <CancelButton onClick={onClose} />
              <OkButton loading={isLoading} />
            </>
          }
        />
      </Dialog>
    </Form>
  );
};

export default FeedbackDialog;

function transform(formData: FeedbackFormData): FeedbackDto {
  return {
    type: formData.type,
    feedback: formData.feedback,
    contactApproval: formData.contactApproval ?? false,
  };
}

const useTypeOptions = () => {
  const { translate } = useTranslation();
  const texts = useTexts().feature.feedback.model.type.values;

  return [
    { id: 'bug_report', title: translate(texts.bug) },
    { id: 'feature_request', title: translate(texts.feature) },
    { id: 'data_issue', title: translate(texts.data) },
    { id: 'usability_issue', title: translate(texts.usability) },
    { id: 'general_feedback', title: translate(texts.general) },
  ];
};
