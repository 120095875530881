import { NavItem } from '../../component/Navigation/types';
import { Icon } from '../../icon/Icon';
import {
  cartFullPath,
  ordersFullPath,
  partsFullPath,
  recommendationsFullPath,
  shoppinglistsFullPath,
} from '../pathDefinitions';
import { RecommendationsNavIcon } from './recommendation/components/RecommendationIcons';

export const ecomNavItems: NavItem[] = [
  {
    to: partsFullPath,
    icon: <Icon name="parts" size="small" />,
    label: ({ ecom }) => ecom.sidebar.parts,
  },
  {
    to: recommendationsFullPath,
    icon: <RecommendationsNavIcon />,
    label: ({ ecom }) => ecom.sidebar.recommendations,
    access: (permissions) => permissions.includes('ecom.reco:r'),
  },
  {
    to: shoppinglistsFullPath,
    icon: <Icon name="shopping_lists" size="small" />,
    label: ({ ecom }) => ecom.sidebar.shoppinglists,
    access: (permissions) => permissions.includes('ecom.order:r'),
  },
  {
    to: ordersFullPath,
    icon: <Icon name="orders" size="small" />,
    label: ({ ecom }) => ecom.sidebar.order,
  },
  {
    to: cartFullPath,
    icon: <Icon name="shopping_cart" size="small" />,
    label: ({ ecom }) => ecom.sidebar.cart,
    access: (permissions) => permissions.includes('ecom.order:w'),
  },
];
