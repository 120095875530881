import { sortBy } from 'lodash-es';
import type { DateRange } from '../../../component/DateRange/types';
import type { SampleParamsStrict } from '../api';
import type { SampleData } from '../api.types';
import { addNoDataSamples } from './noData';
import { addMissingData } from './missingData';
import { addEdgeSamples } from './edges';
import { includeSamplesInDateRange } from './dateRange';

export interface TransformSampleData extends SampleData {
  timeStampAsDate: Date;
}

export type TransformOptions =
  | TransformOptionsEnabled
  | TransformOptionsDisabled;

type TransformOptionsEnabled = {
  enable?: true;
  noDataThresholdSeconds?: number;
  dateRange?: DateRange;
  extrapolation?: {
    [signalName: string]: Extrapolation;
  };
};

type TransformOptionsDisabled = {
  enable: false;
  noDataThresholdSeconds?: never;
  dateRange?: never;
  extrapolation?: never;
};

export interface TransformOptionsStrict {
  noDataThresholdSeconds: number;
  dateRange?: DateRange;
  extrapolation?: {
    [signalName: string]: Extrapolation;
  };
}

export interface SampleTransformSettings {
  options: TransformOptionsStrict;
  query: SampleParamsStrict;
}

export type SampleTransformer = (
  samples: TransformSampleData[],
  settings: SampleTransformSettings
) => TransformSampleData[];

const transformList = [
  addNoDataSamples,
  addMissingData,
  addEdgeSamples,
  includeSamplesInDateRange,
];

const optionsDefault: TransformOptionsStrict = {
  noDataThresholdSeconds: 4 * 60,
};

function addTimeStamp(samples: SampleData[]): TransformSampleData[] {
  return samples.map((sample) => ({
    ...sample,
    timeStampAsDate: new Date(sample.timeStamp),
  }));
}

function removeTimeStamp(samples: TransformSampleData[]): SampleData[] {
  return samples.map(({ timeStampAsDate, ...sample }) => sample);
}

function getOptions(options: TransformOptions = {}): TransformOptionsStrict {
  return {
    ...optionsDefault,
    ...options,
  };
}

function transform(
  samples: SampleData[],
  query: SampleParamsStrict,
  transformOptions?: TransformOptions
) {
  if (transformOptions?.enable === false) {
    return samples;
  }

  const settings = {
    options: getOptions(transformOptions),
    query,
  };

  const transformSamples: TransformSampleData[] = addTimeStamp(samples);

  const transformed = transformList.reduce<TransformSampleData[]>(
    (samples, sampleTransformer) => sampleTransformer(samples, settings),
    transformSamples
  );

  return sortBy(removeTimeStamp(transformed), 'timeStamp');
}

export default transform;
